import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../config/firebase';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill styles
import Swal from 'sweetalert2';
import {
  Container,
  TextField,
  Button,
  Box,
  Typography,
  Paper,
  IconButton,
} from '@mui/material';
import ImageUploader from '../../components/ImageUploader';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import { useTranslation } from 'react-i18next';
import { updateBlog, createBlog } from '../../services/blogService';

function BlogEditor() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [post, setPost] = useState({
    title: '',
    content: '',
    imageUrl: '',
    isDeleted: false
  });
  
  const [selectedImage, setSelectedImage] = useState(null);
  const [errors, setErrors] = useState({});
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir() === 'rtl';

  const translation = {
    title: t('adminBlogEditor.title'),
    createBlog: t('adminBlogEditor.createBlog'),
    editBlog: t('adminBlogEditor.editBlog'),
    updateBlog: t('adminBlogEditor.updateBlog'),
    publishBlog: t('adminBlogEditor.publishBlog'),
    saving: t('adminBlogEditor.saving'),
    vaidation: {
      required: t('adminBlogEditor.validation.required'),
      title: t('adminBlogEditor.validation.title'),
      titleMin: t('adminBlogEditor.validation.titleMin'),
      content: t('adminBlogEditor.validation.content'),
      contentMin: t('adminBlogEditor.validation.contentMin'),
      image: t('adminBlogEditor.validation.image')
    },
    error: {
      title: t('adminBlogEditor.error.title'),
      wrongInData: t('adminBlogEditor.error.wrongInData'),
      fillAllFields: t('adminBlogEditor.error.fillAllFields'),
      save: t('adminBlogEditor.error.save'),
      confirmButtonText: t('adminBlogEditor.error.confirmButtonText')
    },
    success: {
      update: t('adminBlogEditor.success.update'),
      updateMessage: t('adminBlogEditor.success.updateMessage'),
      add: t('adminBlogEditor.success.add'),
      addMessage: t('adminBlogEditor.success.addMessage'),
      confirmButtonText: t('adminBlogEditor.success.confirmButtonText')
    }
  }


  // Updated Quill modules configuration with colors
  const modules = {
    toolbar: [
      [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
      [{ size: [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'color': [] }, { 'background': [] }],  // Add color and background color options
      [{ 'list': 'ordered'}, {'list': 'bullet'}, 
       {'indent': '-1'}, {'indent': '+1'}],
      ['link', 'image'],
      ['clean']
    ],
    clipboard: {
      matchVisual: false,
    }
  };

  // Update formats to include colors
  const formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'color', 'background',
    'list', 'bullet', 'indent',
    'link', 'image'
  ];

  // Updated custom CSS for RTL list styling
  const customStyles = {
    '& .ql-editor': { 
      minHeight: '300px',
      direction: 'rtl',
      textAlign: 'right',
      '& ul, & ol': {
        paddingRight: '2em',
        paddingLeft: '0',
        margin: '0',
      },
      '& li': {
        display: 'flex',
        flexDirection: 'row-reverse',
        justifyContent: 'flex-end',
        position: 'relative',
        '&::before': {
          position: 'absolute',
          right: '-2em',
          marginLeft: '0.5em',
          textAlign: 'right',
        }
      },
      '& ul > li::before': {
        content: '"•"',
      },
      '& ol': {
        counterReset: 'list-counter',
      },
      '& ol > li': {
        counterIncrement: 'list-counter',
      },
      '& ol > li::before': {
        content: 'counter(list-counter) "."',
      }
    }
  };

  useEffect(() => {
    if (id) {
      const fetchPost = async () => {
        const docRef = doc(db, "posts", id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setPost(docSnap.data());
        }
      };
      fetchPost();
    }
  }, [id]);

  // const handleImageUpload = async (e) => {
  //   const file = e.target.files[0];
  //   if (file) {
  //     setSelectedImage(file);
      
  //     // Show preview
  //     const reader = new FileReader();
  //     reader.onload = (e) => {
  //       setPost(prev => ({
  //         ...prev,
  //         imageUrl: e.target.result
  //       }));
  //     };
  //     reader.readAsDataURL(file);
  //   }
  // };

  // Validation function
  const validateForm = () => {
    const newErrors = {};

    // Title validation (required and minimum length)
    if (!post.title.trim()) {
      newErrors.title = translation.vaidation.title;
    } else if (post.title.trim().length < 3) {
      newErrors.title = translation.vaidation.titleMin;
    }

    // Content validation (required and minimum length)
    if (!post.content.trim()) {
      newErrors.content = translation.vaidation.content;
    } else if (post.content.trim().length < 1) {
      newErrors.content = translation.vaidation.contentMin;
    }

    // Image validation (required)
    if (!post.imageUrl && !selectedImage) {
      newErrors.image = translation.vaidation.image;
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Validate form before submission
    if (!validateForm()) {
      Swal.fire({
        icon: 'error',
        title: translation.error.title,
        text: translation.error.fillAllFields,
        confirmButtonText: translation.error.confirmButtonText
      });
      return;
    }

    Swal.fire({
      title: translation.saving,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });

    try {
      if (id) {
        // Update existing blog
        await updateBlog(id, post, selectedImage);
        Swal.fire({
          icon: 'success',
          title: translation.success.update,
          text: translation.success.updateMessage,
          timer: 3000,
          timerProgressBar: true
        });
      } else {
        // Create new blog
        await createBlog(post, selectedImage);
        Swal.fire({
          icon: 'success',
          title: translation.success.add,
          text: translation.success.addMessage,
          timer: 3000,
          timerProgressBar: true
        });
      }
      navigate('/admin/blogs');
    } catch (error) {
      console.error("Error saving post:", error);
      Swal.fire({
        icon: 'error',
        title: translation.error.title,
        text: translation.error.save,
        confirmButtonText: translation.error.confirmButtonText
      });
    }
  };

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <IconButton
        onClick={() => navigate(-1)}
        sx={{ 
          color: '#4CAF50',
          mb: 2,
          '&:hover': {
            bgcolor: 'rgba(76, 175, 80, 0.04)',
          },
          '& .MuiSvgIcon-root': { 
            fontSize: '1.8rem'
          }
        }}
      >
        {isRTL ? <ArrowForwardIcon /> : <ArrowBackIcon />}
      </IconButton>

      <Paper sx={{ p: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom sx={{ textAlign: 'center' }}>
          {id ? translation.editBlog : translation.createBlog}
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            label={translation.title}
            name="title"
            value={post.title}
            onChange={(e) => setPost(prev => ({ ...prev, title: e.target.value }))}
            InputProps={{
              sx: { textAlign: isRTL ? 'right' : 'left' }
            }}
            sx={{
              '& .MuiFormLabel-root': {
                right: isRTL ? '25px' : 'auto',
                left: isRTL ? 'auto' : '10px',
                transformOrigin: 'right',
                '&.MuiInputLabel-shrink': {
                  transform: 'translate(0, -9px) scale(0.75)',
                }
              },
              '& .MuiOutlinedInput-root': {
                '& input': {
                  textAlign: isRTL ? 'right' : 'left',
                },
                '& fieldset': {
                  textAlign: isRTL ? 'right' : 'left',
                  '& legend': {
                    marginRight: isRTL ? '12px' : 'auto',
                    marginLeft: isRTL ? 'auto' : '8px',
                  }
                }
              },
              '& .MuiFormLabel-asterisk': {
                order: 2,
                marginRight: '4px',
                marginLeft: '0'
              },
              '& .MuiInputLabel-root': {
                display: 'flex',
                flexDirection: 'row'
              }
            }}
            error={!!errors.title}
            helperText={errors.title}
          />
          
          {/* Featured Image Upload */}
          <Box sx={{ my: 2 }}>
            <ImageUploader
              onImageUpload={(imageUrl) => {
                setPost(prev => ({ ...prev, imageUrl }));
                setSelectedImage(null);
              }}
              initialImage={post.imageUrl}
              location="blog"
            />
            {errors.image && (
              <Typography color="error" variant="caption" display="block" sx={{ mt: 1 }}>
                {errors.image}
              </Typography>
            )}
          </Box>

          {/* Quill Editor */}
          <Box sx={{ 
            mb: 2, 
            ...customStyles,
            '& .ql-container': {
              fontFamily: 'inherit'
            },
            // Add styles for color picker dropdown
            '& .ql-picker-options': {
              direction: 'ltr', // Keep color picker LTR
              textAlign: 'left'
            },
            // Ensure color swatches are visible
            '& .ql-color-picker': {
              '& .ql-picker-options': {
                padding: '5px',
                width: '152px'
              }
            }
          }}>
            <ReactQuill
              theme="snow"
              value={post.content}
              onChange={(content) => setPost(prev => ({ ...prev, content }))}
              modules={modules}
              formats={formats}
            />
          </Box>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ 
              mt: 8, 
              mb: 2,
              bgcolor: '#1976d2', // Material-UI primary blue
              color: 'white',
              '&:hover': {
                bgcolor: '#1565c0', // Darker blue on hover
              },
              borderRadius: '4px',
              textTransform: 'none', // Prevents uppercase transformation
              fontWeight: 'normal',
              fontSize: '1rem',
              padding: '8px 16px',
            }}
          >
            {id ? translation.updateBlog : translation.publishBlog}
          </Button>
        </Box>
      </Paper>
    </Container>
  );
}

export default BlogEditor; 