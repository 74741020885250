import { collection, onSnapshot, query, orderBy, where, runTransaction } from 'firebase/firestore';
import { db } from '../config/firebase';
import { getDocs, addDoc, updateDoc, doc } from 'firebase/firestore';

const COLLECTION_NAME = 'research';

export const subscribeToResearches = (callback) => {
  try {
    console.log('Setting up research subscription...');
    const q = query(
      collection(db, COLLECTION_NAME),
      where('isVisible', '==', true),
      orderBy('createdAt', 'desc')
    );

    return onSnapshot(q, 
      (snapshot) => {
        console.log('Received research data:', snapshot.docs.length, 'documents');
        const researches = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        callback(researches);
      },
      (error) => {
        console.error('Error in research subscription:', error);
        if (error.code === 'failed-precondition') {
          console.error('Index may be missing. Error details:', error);
        }
        callback([]);
      }
    );
  } catch (error) {
    console.error('Error setting up research subscription:', error);
    callback([]);
    return () => {};
  }
};

export const getResearch = async () => {
  try {
    const q = query(
      collection(db, COLLECTION_NAME),
      orderBy('createdAt', 'desc')
    );
    
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs
      .map(doc => ({
        id: doc.id,
        ...doc.data()
      }))
      .filter(doc => !doc.isDeleted);
  } catch (error) {
    console.error('Error fetching research:', error);
    return [];
  }
};

export const addResearch = async (researchData) => {
  return await addDoc(collection(db, COLLECTION_NAME), {...researchData, clicks: 0, createdAt: new Date().toISOString()});
};

export const updateResearch = async (id, researchData) => {
  const docRef = doc(db, COLLECTION_NAME, id);
  return await updateDoc(docRef, researchData);
};

export const deleteResearch = async (id, userId) => {
  try {
    const docRef = doc(db, COLLECTION_NAME, id);
    return await updateDoc(docRef, {
      isDeleted: true,
      isVisible: false,
      deletedBy: userId,
      deletedAt: new Date().toISOString()
    });
  } catch (error) {
    throw new Error(`Failed to delete research: ${error.message}`);
  }
};

export const incrementResearchClicks = async (researchId) => {
  try {
    const researchRef = doc(db, COLLECTION_NAME, researchId);
    
    await runTransaction(db, async (transaction) => {
      const researchDoc = await transaction.get(researchRef);
      
      if (!researchDoc.exists()) {
        throw new Error("Document does not exist!");
      }

      // Get current clicks or default to 0
      const currentClicks = researchDoc.data().clicks || 0;
      
      // Update the clicks count
      transaction.update(researchRef, {
        clicks: currentClicks + 1
      });
    });
  } catch (error) {
    console.error('Error incrementing research clicks:', error);
  }
}; 