import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getAllBlogs, deleteBlog, updateBlogVisibility } from '../../services/blogService';
import Swal from 'sweetalert2';
import {
  Container,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  InputBase,
  CircularProgress,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import TableSortLabel from '@mui/material/TableSortLabel';
import AddIcon from '@mui/icons-material/Add';
import { useAuth } from '../../contexts/AuthContext';
import Switch from '@mui/material/Switch';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useTranslation } from 'react-i18next';


function AdminBlogs() {
  const [blogs, setBlogs] = useState([]);
  const { user } = useAuth();
  const [searchTerm, setSearchTerm] = useState('');
  const [sortField, setSortField] = useState('createdAt');
  const [sortDirection, setSortDirection] = useState('desc');
  const [loading, setLoading] = useState(true);
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir() === 'rtl';

  const translation = {
   title: t('adminBlog.title'),
   addPost: t('adminBlog.addPost'),
   editPost: t('adminBlog.editPost'),
   deletePost: t('adminBlog.deletePost'),
   updatePost: t('adminBlog.updatePost'),
   blogTitle: t('adminBlog.blogTitle'),
   createdDate: t('adminBlog.createdDate'),
   views: t('adminBlog.views'),
   status: t('adminBlog.status'),
   actions: t('adminBlog.actions'),
   noPosts: t('adminBlog.noPosts'),
   search: t('adminBlog.search'),
   fetchingError: {
    title: t('adminBlog.fetchginError.title'),
    text: t('adminBlog.fetchginError.text'),
    confirmButtonText: t('adminBlog.fetchginError.confirmButtonText')
   },
   confirm: {
    title: t('adminBlog.confirm.title'),
    text: t('adminBlog.confirm.text'),
    confirmButtonText: t('adminBlog.confirm.confirmButtonText'),
    cancelButtonText: t('adminBlog.confirm.cancelButtonText')
   },
   success: {
    title: t('adminBlog.success.title'),
    text: t('adminBlog.success.text'),
    confirmButtonText: t('adminBlog.success.confirmButtonText')
   },
   error: {
    title: t('adminBlog.error.title'),
    delete: t('adminBlog.error.delete'),
    update: t('adminBlog.error.update'),
    confirmButtonText: t('adminBlog.error.confirmButtonText')
   }
  }


  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        setLoading(true);
        const data = await getAllBlogs();
        const activePosts = data.filter(post => !post.isDeleted);
        const sortedBlogs = activePosts.sort((a, b) => 
          new Date(b.createdAt) - new Date(a.createdAt)
        );
        setBlogs(sortedBlogs);
      } catch (error) {
        console.error('Error fetching blogs:', error);
        Swal.fire({
          icon: 'error',
          title: translation.fetchingError.title,
          text: translation.fetchingError.text,
          confirmButtonText: translation.fetchingError.confirmButtonText
        });
      } finally {
        setLoading(false);
      }
    };
    
    fetchBlogs();
  }, []);



  const handleSortClick = (field) => {
    if (field === sortField) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
  };

  const filteredAndSortedBlogs = blogs
    .filter(blog => 
      blog.title.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .sort((a, b) => {
      if (sortField === 'createdAt') {
        return sortDirection === 'asc' 
          ? new Date(a.createdAt) - new Date(b.createdAt)
          : new Date(b.createdAt) - new Date(a.createdAt);
      }
      if (sortField === 'views') {
        const aViews = a.views || 0;
        const bViews = b.views || 0;
        return sortDirection === 'asc' ? aViews - bViews : bViews - aViews;
      }
      return sortDirection === 'asc'
        ? a[sortField]?.localeCompare(b[sortField])
        : b[sortField]?.localeCompare(a[sortField]);
    });

  const handleDelete = async (id) => {
    const result = await Swal.fire({
      title: translation.confirm.title,
      text: translation.confirm.text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: translation.confirm.confirmButtonText,
      cancelButtonText: translation.confirm.cancelButtonText
    });

    if (result.isConfirmed) {
      try {
        await deleteBlog(id, user.uid);
        setBlogs(blogs.filter(blog => blog.id !== id));
        Swal.fire({
          icon: 'success',
          title: translation.success.title,
          text: translation.success.text,
          confirmButtonText: translation.success.confirmButtonText
        });
      } catch (error) {
        console.error('Error deleting blog:', error);
        Swal.fire({
          icon: 'error',
          title: translation.error.title,
          text: translation.error.delete,
          confirmButtonText: translation.error.confirmButtonText
        });
      }
    }
  };

  const handleVisibilityToggle = async (id, currentVisibility) => {
    try {
      await updateBlogVisibility(id, !currentVisibility);
      setBlogs(blogs.map(blog => 
        blog.id === id ? { ...blog, isVisible: !currentVisibility } : blog
      ));
    } catch (error) {
      console.error('Error updating blog visibility:', error);
      Swal.fire({
        icon: 'error',
        title: translation.error.title,
        text: translation.error.update,
        confirmButtonText: translation.error.confirmButtonText
      });
    }
  };

  return (
    <Container maxWidth="xl" sx={{ mt: 4, direction: isRTL ? 'rtl' : 'ltr' }}>
      <Typography 
        variant="h4" 
        sx={{ 
          fontWeight: 600,
          color: '#2c3e50',
          fontSize: { xs: '1.5rem', sm: '1.8rem', md: '2rem' },
          textAlign: 'center',
          my: 3
        }}
      >
        {translation.title}
      </Typography>

      <Box sx={{ 
        width: '100%', 
        maxWidth: '1600px',
        display: 'flex', 
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 2,
        px: { xs: 2, sm: 3, md: 0 },
        mb: 3,
        direction: isRTL ? 'rtl' : 'ltr'
      }}>
        <Box sx={{ 
          display: 'flex',
          flex: 1,
          position: 'relative',
          bgcolor: '#f8f9fa',
          borderRadius: '12px',
          border: '1px solid #e0e0e0',
          height: '50px'
        }}>
          <SearchIcon sx={{ 
            position: 'absolute',
            left: isRTL ? 'auto' : 16,
            right: isRTL ? 16 : 'auto',
            top: '50%',
            transform: 'translateY(-50%)',
            color: '#94a3b8',
            fontSize: '1.5rem'
          }} />
          <InputBase
            placeholder={translation.search + '...'}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{
              flex: 1,
              pl: isRTL ? 3 : 6,
              pr: isRTL ? 6 : 3,
              fontSize: '1.1rem',
              height: '100%',
              direction: isRTL ? 'rtl' : 'ltr'
            }}
          />
        </Box>
        <IconButton
          component={Link}
          to="/admin/create"
          sx={{ 
            color: '#4CAF50',
            width: '50px',
            height: '50px',
            '&:hover': {
              bgcolor: 'rgba(76, 175, 80, 0.04)',
            },
            '& .MuiSvgIcon-root': { 
              fontSize: '1.8rem'
            }
          }}
        >
          <AddIcon />
        </IconButton>
      </Box>

      <TableContainer 
        component={Paper} 
        sx={{ 
          boxShadow: '0 2px 12px rgba(0,0,0,0.08)',
          borderRadius: '12px',
          overflow: 'hidden',
          width: '100%',
          maxWidth: '1600px',
          margin: '0 auto',
          position: 'relative',
          minHeight: '200px',
          direction: isRTL ? 'rtl' : 'ltr'
        }}
      >
        {loading ? (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              bgcolor: 'rgba(255, 255, 255, 0.8)',
              zIndex: 1,
            }}
          >
            <CircularProgress 
              size={60}
              thickness={4}
              sx={{
                color: '#4CAF50',
              }}
            />
          </Box>
        ) : null}
        
        {isMobile ? (
          <Box sx={{ p: 2 }}>
            {filteredAndSortedBlogs.map((blog) => (
              <Paper
                key={blog.id}
                sx={{
                  p: 2,
                  mb: 2,
                  borderRadius: '12px',
                  boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                  overflow: 'hidden'
                }}
              >
                <Typography 
                  sx={{ 
                    fontWeight: 600,
                    fontSize: '1.1rem',
                    mb: 2,
                    textAlign: "right",
                    direction: 'rtl',
                    wordSpacing: '0.05em',
                    hyphens: 'auto',
                    WebkitHyphens: 'auto',
                    MsHyphens: 'auto'
                  }}
                >
                  {blog.title}
                </Typography>

                <Box sx={{ 
                  display: 'flex', 
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  borderTop: '1px solid #e2e8f0',
                  pt: 2,
                  mt: 2
                }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Typography 
                      variant="caption" 
                      color="text.secondary"
                    >
                      {new Date(blog.createdAt).toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric'
                      })}
                    </Typography>
                    <Typography 
                      variant="caption" 
                      sx={{ 
                        color: 'text.secondary',
                        display: 'flex',
                        alignItems: 'center',
                        gap: 0.5
                      }}
                    >
                      <VisibilityIcon sx={{ fontSize: '1rem' }} />
                      {blog.views || 0}
                    </Typography>
                    <Switch
                      checked={blog.isVisible}
                      onChange={() => handleVisibilityToggle(blog.id, blog.isVisible)}
                      color="primary"
                      size="small"
                    />
                  </Box>

                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <IconButton
                      component={Link}
                      to={`/admin/edit/${blog.id}`}
                      size="small"
                      sx={{ 
                        color: '#3498db',
                        bgcolor: 'rgba(52, 152, 219, 0.1)',
                        '&:hover': { bgcolor: 'rgba(52, 152, 219, 0.2)' }
                      }}
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      onClick={() => handleDelete(blog.id)}
                      size="small"
                      sx={{ 
                        color: '#e74c3c',
                        bgcolor: 'rgba(231, 76, 60, 0.1)',
                        '&:hover': { bgcolor: 'rgba(231, 76, 60, 0.2)' }
                      }}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Box>
                </Box>
              </Paper>
            ))}
          </Box>
        ) : (
          <Table>
            <TableHead>
              <TableRow sx={{ bgcolor: '#f8f9fa' }}>
                <TableCell 
                  align="right" 
                  sx={{ 
                    fontWeight: 600,
                    color: '#2c3e50',
                    fontSize: '0.95rem',
                    borderBottom: '2px solid #e9ecef',
                    textAlign: isRTL ? 'right' : 'left'
                  }}
                >
                  <TableSortLabel
                    active={sortField === 'title'}
                    direction={sortField === 'title' ? sortDirection : 'asc'}
                    onClick={() => handleSortClick('title')}
                  >
                    {translation.blogTitle}
                  </TableSortLabel>
                </TableCell>
                <TableCell 
                  align="right"
                  sx={{ 
                    fontWeight: 600,
                    color: '#2c3e50',
                    fontSize: '0.95rem',
                    borderBottom: '2px solid #e9ecef',
                    textAlign: isRTL ? 'right' : 'left'
                  }}
                >
                  <TableSortLabel
                    active={sortField === 'createdAt'}
                    direction={sortField === 'createdAt' ? sortDirection : 'asc'}
                    onClick={() => handleSortClick('createdAt')}
                  >
                    {translation.createdDate}
                  </TableSortLabel>
                </TableCell>
                <TableCell 
                  align="center"
                  sx={{ 
                    fontWeight: 600,
                    color: '#2c3e50',
                    fontSize: '0.95rem',
                    borderBottom: '2px solid #e9ecef',
                  }}
                >
                  <TableSortLabel
                    active={sortField === 'views'}
                    direction={sortField === 'views' ? sortDirection : 'asc'}
                    onClick={() => handleSortClick('views')}
                    sx={{
                      width: '100%',
                      justifyContent: 'center',
                      '& .MuiTableSortLabel-icon': {
                        position: 'absolute',
                        right: -20,
                      }
                    }}
                  >
                    {translation.views}
                  </TableSortLabel>
                </TableCell>
                <TableCell 
                  align="center"
                  sx={{ 
                    fontWeight: 600,
                    color: '#2c3e50',
                    fontSize: '0.95rem',
                    borderBottom: '2px solid #e9ecef',
                  }}
                >
                  {translation.status}
                </TableCell>
                <TableCell 
                  align="center"
                  sx={{ 
                    fontWeight: 600,
                    color: '#2c3e50',
                    fontSize: '0.95rem',
                    borderBottom: '2px solid #e9ecef',
                  }}
                >
                  {translation.actions}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredAndSortedBlogs.map((blog) => (
                <TableRow 
                  key={blog.id}
                  sx={{ 
                    '&:hover': { 
                      bgcolor: '#f8f9fa',
                      transition: 'background-color 0.3s ease'
                    },
                  }}
                >
                  <TableCell 
                    align="right" 
                    sx={{ 
                      textAlign: isRTL ? 'right' : 'left',
                      color: '#34495e',
                      maxWidth: '500px',
                      '& .content': {
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        transition: 'all 0.3s ease',
                      },
                      '&:hover .content': {
                        whiteSpace: 'wrap',
                        maxWidth: '100%'
                      }
                    }}
                  >
                    <div className="content">{blog.title}</div>
                  </TableCell>
                  <TableCell 
                    align="right"
                    sx={{ 
                      color: '#34495e',
                      textAlign: isRTL ? 'right' : 'left'
                    }}
                  >
                    {new Date(blog.createdAt).toLocaleDateString('en-US', {
                      year: 'numeric',
                      month: 'short',
                      day: 'numeric'
                    })}
                  </TableCell>
                  <TableCell 
                    align="center"
                    sx={{ 
                      color: '#34495e', 
                      width: '120px'
                    }}
                  >
                    {blog.views || 0}
                  </TableCell>
                  <TableCell 
                    align="center"
                    sx={{ color: '#34495e' }}
                  >
                    <Switch
                      checked={blog.isVisible}
                      onChange={() => handleVisibilityToggle(blog.id, blog.isVisible)}
                      color="primary"
                      sx={{
                        '& .MuiSwitch-switchBase.Mui-checked': {
                          color: '#1976d2'
                        },
                        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                          backgroundColor: '#1976d2'
                        }
                      }}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1 }}>
                      <IconButton
                        component={Link}
                        to={`/admin/edit/${blog.id}`}
                        sx={{ 
                          color: '#3498db',
                          '&:hover': { 
                            color: '#2980b9',
                            bgcolor: 'rgba(52, 152, 219, 0.1)'
                          }
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => handleDelete(blog.id)}
                        sx={{ 
                          color: '#e74c3c',
                          '&:hover': { 
                            color: '#c0392b',
                            bgcolor: 'rgba(231, 76, 60, 0.1)'
                          }
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
    </Container>
  );
}

export default AdminBlogs; 