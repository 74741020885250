import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
import { subscribeToVisibleBlogs } from '../../services/blogService';
import { 
  Container, 
  Grid, 
  Card, 
  CardContent, 
  CardMedia, 
  Typography, 
  CardActionArea,
  Box
} from '@mui/material';
import { CircularProgress } from '@mui/material';
import ImageIcon from '@mui/icons-material/Image';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useTranslation } from 'react-i18next';

function isRTLText(text) {
  // Check for RTL characters (Arabic, Hebrew, etc.)
  const rtlRegex = /[\u0591-\u07FF\u200F\u202B\u202E\uFB1D-\uFDFD\uFE70-\uFEFC]/;
  return rtlRegex.test(text);
}

function BlogList() {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  // const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    // Set up the subscription to visible blogs
    const unsubscribe = subscribeToVisibleBlogs((updatedPosts, error) => {
      if (error) {
        console.error("Error in blog subscription:", error);
        return;
      }
      setPosts(updatedPosts);
      setLoading(false);
    });

    // Clean up subscription when component unmounts
    return () => unsubscribe();
  }, []);

  const tranlsation = {
    noPosts: 'blog.noPosts',
    soon: 'blog.soon',
    readMore: 'blog.readMore',
  }

  const handlePostClick = (postId) => {
    window.open(`/blog/${postId}`, '_blank', 'noopener,noreferrer');
  };

  if (loading) {
    return (
      <Container 
        sx={{ 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center',
          minHeight: '60vh',
          mt: 10
        }}
      >
        <CircularProgress size={60} thickness={4} sx={{ color: '#4CAF50' }} />
      </Container>
    );
  }

  if (!posts || posts.length === 0) {
    return (
      <Box sx={{ mt: 10 }}>
        <Container maxWidth="lg" sx={{ py: 6 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              minHeight: '40vh',
              flexDirection: 'column',
              gap: 2
            }}
          >
            <Typography 
              variant="h4" 
              component="h1" 
              sx={{ 
                textAlign: 'center',
                fontWeight: 'bold',
                color: 'text.secondary'
              }}
            >
              {t(tranlsation.noPosts)}
            </Typography>
            <Typography 
              variant="body1" 
              sx={{ 
                textAlign: 'center',
                color: 'text.secondary'
              }}
            >
              {t(tranlsation.soon)}
            </Typography>
          </Box>
        </Container>
      </Box>
    );
  }

  return (
    <Box sx={{ mt: 10 }}>
      <Container maxWidth="lg" sx={{ py: 6 }}>
        <Grid container spacing={4}>
          {posts.map((post) => (
            <Grid item xs={12} sm={6} md={4} key={post.id}>
              <Card 
                sx={{ 
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  transition: 'transform 0.2s ease-in-out',
                  cursor: 'pointer',
                  '&:hover': {
                    transform: 'translateY(-5px)',
                    boxShadow: 3
                  }
                }}
                onClick={() => handlePostClick(post.id)}
              >
                <CardActionArea>
                  {post.imageUrl ? (
                    <CardMedia
                      component="img"
                      height="240"
                      image={post.imageUrl}
                      alt={post.title}
                      sx={{ objectFit: 'cover' }}
                    />
                  ) : (
                    <Box
                      sx={{
                        height: 240,
                        backgroundColor: '#f5f5f5',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      <ImageIcon sx={{ fontSize: 60, color: '#bdbdbd' }} />
                    </Box>
                  )}
                  <CardContent sx={{ flexGrow: 1, p: 3 }}>
                    <Typography 
                      variant="h5" 
                      component="h2"
                      sx={{
                        fontWeight: 'bold',
                        mb: 2,
                        lineHeight: 1.4,
                        direction: isRTLText(post.title) ? 'rtl' : 'ltr',
                        textAlign: isRTLText(post.title) ? 'right' : 'left'
                      }}
                    >
                      {post.title}
                    </Typography>
                    <Typography 
                      variant="body1" 
                      color="text.secondary"
                      sx={{
                        lineHeight: 1.6,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        '& p, & span': {
                          margin: 0,
                          lineHeight: 'inherit',
                        },
                        direction: isRTLText(post.content || '') ? 'rtl' : 'ltr',
                        textAlign: isRTLText(post.content || '') ? 'right' : 'left'
                      }}
                    >
                      <div 
                        dangerouslySetInnerHTML={{ 
                          __html: post.excerpt || post.content?.substring(0, 150) + '...' + 
                            `<span style="color: green; font-style: italic">${t(tranlsation.readMore)}</span>`
                        }} 
                      />
                    </Typography>
                    <Box sx={{ 
                      display: 'flex', 
                      alignItems: 'center', 
                      justifyContent: 'space-between',
                      mt: 2 
                    }}>
                      <Typography 
                        variant="caption" 
                        sx={{ 
                          color: 'text.secondary',
                          fontSize: '0.75rem'
                        }}
                      >
                        {new Date(post.createdAt).toLocaleDateString('en-US', {
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric'
                        })}
                      </Typography>
                      <Typography 
                        variant="caption" 
                        sx={{ 
                          color: 'text.secondary',
                          fontSize: '0.75rem',
                          display: 'flex',
                          alignItems: 'center',
                          gap: 0.5
                        }}
                      >
                        <VisibilityIcon sx={{ fontSize: '1rem' }} />
                        {post.views || 0}
                      </Typography>
                    </Box>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}

export default BlogList; 