import { db, storage } from '../config/firebase';
import { collection, addDoc, updateDoc, doc, getDocs, getDoc, query, where, orderBy, onSnapshot, runTransaction } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

const COLLECTION_NAME = 'posts';

export const createBlog = async (blogData, image) => {
  try {
    const docRef = await addDoc(collection(db, COLLECTION_NAME), {
      ...blogData,
      createdAt: new Date().toISOString(),
    });
    return docRef.id;
  } catch (error) {
    throw error;
  }
};

export const updateBlog = async (id, blogData, image) => {
  try {
    const blogRef = doc(db, COLLECTION_NAME, id);
    let updateData = { ...blogData };

    if (image) {
      const imageRef = ref(storage, `blog-images/${Date.now()}-${image.name}`);
      const uploadResult = await uploadBytes(imageRef, image);
      const imageUrl = await getDownloadURL(uploadResult.ref);
      updateData.imageUrl = imageUrl;
    }

    await updateDoc(blogRef, updateData);
  } catch (error) {
    throw error;
  }
};

export const deleteBlog = async (id, userId) => {
  try {
    const blogRef = doc(db, COLLECTION_NAME, id);
    await updateDoc(blogRef, {
      isDeleted: true,
      deletedBy: userId,
      deletedAt: new Date().toISOString()
    });
  } catch (error) {
    throw new Error(`Failed to delete blog: ${error.message}`);
  }
};

export const getAllBlogs = async () => {
  try {
    // Simple query without compound filters
    const q = query(
      collection(db, COLLECTION_NAME),
      orderBy('createdAt', 'desc')
    );
    const querySnapshot = await getDocs(q);
    
    // Filter deleted posts in memory
    const blogs = querySnapshot.docs
      .map(doc => ({
        id: doc.id,
        ...doc.data()
      }))
      .filter(blog => !blog.isDeleted);
    
    return blogs;
  } catch (error) {
    console.error('Error fetching blogs:', error);
    throw new Error(`Failed to fetch blogs: ${error.message}`);
  }
};

export const getBlogById = async (id) => {
  try {
    const docRef = doc(db, COLLECTION_NAME, id);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return { id: docSnap.id, ...docSnap.data() };
    }
    return null;
  } catch (error) {
    throw new Error(`Failed to fetch blog: ${error.message}`);
  }
};

export const subscribeToBlogUpdates = (callback) => {
  try {
    const q = query(
      collection(db, COLLECTION_NAME),
      orderBy('createdAt', 'desc')
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const blogs = querySnapshot.docs
        .map(doc => ({
          id: doc.id,
          ...doc.data()
        }))
        .filter(blog => !blog.isDeleted);
      
      callback(blogs);
    }, (error) => {
      console.error('Error in blog subscription:', error);
      callback([], error);
    });

    return unsubscribe;
  } catch (error) {
    console.error('Error setting up blog subscription:', error);
    throw new Error(`Failed to subscribe to blogs: ${error.message}`);
  }
};

export const updateBlogVisibility = async (blogId, isVisible) => {
  try {
    const blogRef = doc(db, COLLECTION_NAME, blogId);
    await updateDoc(blogRef, {
      isVisible: isVisible
    });
    return true;
  } catch (error) {
    console.error('Error updating blog visibility:', error);
    throw error;
  }
};

export const subscribeToVisibleBlogs = (callback) => {
  try {
    const q = query(
      collection(db, COLLECTION_NAME),
      where('isVisible', '==', true),
      where('isDeleted', '==', false),
      orderBy('createdAt', 'desc')
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const blogs = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      
      callback(blogs);
    }, (error) => {
      console.error('Error in visible blogs subscription:', error);
      callback([], error);
    });

    return unsubscribe;
  } catch (error) {
    console.error('Error setting up visible blogs subscription:', error);
    throw new Error(`Failed to subscribe to visible blogs: ${error.message}`);
  }
};

export const subscribeToBlogById = (blogId, callback) => {
  try {
    const blogRef = doc(db, COLLECTION_NAME, blogId);
    
    const unsubscribe = onSnapshot(blogRef, (doc) => {
      if (doc.exists()) {
        const blogData = { id: doc.id, ...doc.data() };
        if (!blogData.isDeleted && blogData.isVisible) {
          callback(blogData);
        } else {
          callback(null);
        }
      } else {
        callback(null);
      }
    }, (error) => {
      console.error('Error in blog subscription:', error);
      callback(null, error);
    });

    return unsubscribe;
  } catch (error) {
    console.error('Error setting up blog subscription:', error);
    callback(null, error);
    return () => {};
  }
};

export const incrementBlogViews = async (blogId) => {
  try {
    const blogRef = doc(db, COLLECTION_NAME, blogId);
    
    await runTransaction(db, async (transaction) => {
      const blogDoc = await transaction.get(blogRef);
      
      if (!blogDoc.exists()) {
        throw new Error("Document does not exist!");
      }

      // Get current views or default to 0
      const currentViews = blogDoc.data().views || 0;
      
      // Update the views count
      transaction.update(blogRef, {
        views: currentViews + 1
      });
    });
  } catch (error) {
    console.error('Error incrementing blog views:', error);
  }
};