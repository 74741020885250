import React, { useState, useEffect } from 'react';
import {
  Container,
  Paper,
  Typography,
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  InputBase,
  TableSortLabel,
  CircularProgress,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import { subscribeToContacts, deleteContact, markAsRead } from '../../services/contactService';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';

function AdminContacts() {
  const [contacts, setContacts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);
  const [sortField, setSortField] = useState('createdAt');
  const [sortDirection, setSortDirection] = useState('desc');
  // const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir() === 'rtl';

  const translation = {
    title: t('adminMessage.title'),
    name: t('adminMessage.name'),
    email: t('adminMessage.email'),
    subject: t('adminMessage.subject'),
    message: t('adminMessage.message'),
    status: t('adminMessage.status'),
    actions: t('adminMessage.actions'),
    delete: t('adminMessage.delete'),
    unread: t('adminMessage.unread'),
    read: t('adminMessage.read'),
    makeRead: t('adminMessage.makeRead'),
    makeUnread: t('adminMessage.makeUnread'),
    noMessages: t('adminMessage.noMessages'),
    search: t('adminMessage.search'),
    confirm: {
      title: t('adminMessage.confirm.title'),
      text: t('adminMessage.confirm.text'),
      confirmButtonText: t('adminMessage.confirm.confirmButtonText'),
      cancelButtonText: t('adminMessage.confirm.cancelButtonText')
    },
    success: {
      title: t('adminMessage.success.title'),
      text: t('adminMessage.success.text'),
      confirmButtonText: t('adminMessage.success.confirmButtonText')
    },
    error: {
      title: t('adminMessage.error.title'),
      text: t('adminMessage.error.text'),
      confirmButtonText: t('adminMessage.error.confirmButtonText')
    },
    update: {
      title: t('adminMessage.update.title'),
      read: t('adminMessage.update.read'),
      unread: t('adminMessage.update.unread')
    },
    updateError: {
      title: t('adminMessage.updateError.title'),
      text: t('adminMessage.updateError.text'),
      confirmButtonText: t('adminMessage.updateError.confirmButtonText')
    }
  }
  

  useEffect(() => {
    const unsubscribe = subscribeToContacts((updatedContacts) => {
      setContacts(updatedContacts);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const handleDelete = async (id) => {
    try {
      const result = await Swal.fire({
        title: translation.confirm.title,
        text: translation.confirm.text,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: translation.confirm.confirmButtonText,
        cancelButtonText: translation.confirm.cancelButtonText,
        customClass: {
          container: 'swal2-rtl'
        }
      });

      if (result.isConfirmed) {
        await deleteContact(id);
        Swal.fire({
          title: translation.success.title,
          text: translation.success.text,
          icon: 'success',
          customClass: {
            container: 'swal2-rtl'
          }
        });
      }
    } catch (error) {
      console.error('Error deleting contact:', error);
      Swal.fire({
        title: translation.error.title,
        text: translation.error.text,
        icon: 'error',
        customClass: {
          container: 'swal2-rtl'
        }
      });
    }
  };

  const handleMarkAsRead = async (id, currentReadStatus) => {
    try {
      await markAsRead(id, !currentReadStatus);
      Swal.fire({
        title: translation.update.title,
        text: !currentReadStatus ? translation.update.read : translation.update.unread,
        icon: 'success',
        customClass: {
          container: 'swal2-rtl'
        }
      });
    } catch (error) {
      console.error('Error toggling contact read status:', error);
      Swal.fire({
        title: translation.updateError.title,
        text: translation.updateError.text,
        icon: 'error',
        customClass: {
          container: 'swal2-rtl'
        }
      });
    }
  };

  const handleSortClick = (field) => {
    if (field === sortField) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
  };

  const filteredAndSortedContacts = contacts
    .filter(contact =>
      contact.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      contact.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      contact.subject.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .sort((a, b) => {
      if (sortField === 'createdAt') {
        return sortDirection === 'asc' 
          ? new Date(a.createdAt) - new Date(b.createdAt)
          : new Date(b.createdAt) - new Date(a.createdAt);
      }
      return sortDirection === 'asc'
        ? (a[sortField] || '').localeCompare(b[sortField] || '')
        : (b[sortField] || '').localeCompare(a[sortField] || '');
    });

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Paper sx={{ p: 3, mb: 4 }}>
        <Typography variant="h4" gutterBottom sx={{ 
          textAlign: 'center', 
          mb: 4,
          direction: isRTL ? 'rtl' : 'ltr'
        }}>
          {translation.title}
        </Typography>

        {loading ? (
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center', 
            minHeight: '300px' 
          }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Box sx={{ 
              display: 'flex',
              alignItems: 'center',
              mb: 3,
              gap: 2,
              direction: isRTL ? 'rtl' : 'ltr'
            }}>
              <Box sx={{ 
                display: 'flex',
                flex: 1,
                position: 'relative',
                bgcolor: '#f8f9fa',
                borderRadius: '12px',
                border: '1px solid #e0e0e0',
                height: '50px'
              }}>
                <SearchIcon sx={{ 
                  position: 'absolute',
                  [isRTL ? 'right' : 'left']: 16,
                  top: '50%',
                  transform: 'translateY(-50%)',
                  color: '#94a3b8',
                  fontSize: '1.5rem'
                }} />
                <InputBase
                  placeholder={translation.search + "..."}
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  sx={{
                    flex: 1,
                    pl: isRTL ? 3 : 6,
                    pr: isRTL ? 6 : 3,
                    fontSize: '1.1rem',
                    height: '100%',
                    direction: isRTL ? 'rtl' : 'ltr'
                  }}
                />
              </Box>
            </Box>

            {filteredAndSortedContacts.length > 0 ? (
              <TableContainer>
                <Table sx={{ direction: isRTL ? 'rtl' : 'ltr' }}>
                  <TableHead>
                    <TableRow sx={{ bgcolor: '#f8f9fa' }}>
                      <TableCell align={isRTL ? 'right' : 'left'}>
                        <TableSortLabel
                          active={sortField === 'name'}
                          direction={sortField === 'name' ? sortDirection : 'asc'}
                          onClick={() => handleSortClick('name')}
                        >
                          {translation.name}
                        </TableSortLabel>
                      </TableCell>
                      <TableCell align={isRTL ? 'right' : 'left'}>
                        <TableSortLabel
                          active={sortField === 'email'}
                          direction={sortField === 'email' ? sortDirection : 'asc'}
                          onClick={() => handleSortClick('email')}
                        >
                          {translation.email}
                        </TableSortLabel>
                      </TableCell>
                      <TableCell align={isRTL ? 'right' : 'left'}>
                        <TableSortLabel
                          active={sortField === 'subject'}
                          direction={sortField === 'subject' ? sortDirection : 'asc'}
                          onClick={() => handleSortClick('subject')}
                        >
                          {translation.subject}
                        </TableSortLabel>
                      </TableCell>
                      <TableCell align={isRTL ? 'right' : 'left'}>
                        <TableSortLabel
                          active={sortField === 'message'}
                          direction={sortField === 'message' ? sortDirection : 'asc'}
                          onClick={() => handleSortClick('message')}
                        >
                          {translation.message}
                        </TableSortLabel>
                      </TableCell>
                      <TableCell align={isRTL ? 'right' : 'left'}>
                        <TableSortLabel
                          active={sortField === 'createdAt'}
                          direction={sortField === 'createdAt' ? sortDirection : 'asc'}
                          onClick={() => handleSortClick('createdAt')}
                        >
                          {translation.status}
                        </TableSortLabel>
                      </TableCell>
                      <TableCell align="center">{translation.actions}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredAndSortedContacts.map((contact) => (
                      <TableRow key={contact.id}>
                        <TableCell align={isRTL ? 'right' : 'left'}>{contact.name}</TableCell>
                        <TableCell align={isRTL ? 'right' : 'left'}>{contact.email}</TableCell>
                        <TableCell align={isRTL ? 'right' : 'left'}>{contact.subject}</TableCell>
                        <TableCell align={isRTL ? 'right' : 'left'}>{contact.message}</TableCell>
                        <TableCell align={isRTL ? 'right' : 'left'}>
                          <Chip 
                            label={contact.isRead ? translation.read : translation.unread}
                            color={contact.isRead ? "default" : "success"}
                            size="small"
                          />
                        </TableCell>
                        <TableCell align="center">
                          <IconButton
                            onClick={() => handleMarkAsRead(contact.id, contact.isRead)}
                            sx={{ 
                              color: contact.isRead ? 'grey.400' : 'success.main',
                              '&:hover': { 
                                color: contact.isRead ? 'success.main' : 'success.dark',
                                bgcolor: 'rgba(46, 204, 113, 0.1)'
                              }
                            }}
                            title={contact.isRead ? translation.makeUnread : translation.makeRead}
                          >
                            <MarkEmailReadIcon />
                          </IconButton>
                          <IconButton
                            onClick={() => handleDelete(contact.id)}
                            sx={{ 
                              color: 'error.main',
                              '&:hover': { color: 'error.dark' }
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Box 
                sx={{ 
                  textAlign: 'center', 
                  py: 8,
                  px: 2,
                  bgcolor: '#f8f9fa',
                  borderRadius: '12px',
                  mt: 4
                }}
              >
                <Box 
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 2
                  }}
                >
                  <Box 
                    sx={{
                      width: 80,
                      height: 80,
                      borderRadius: '50%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      mb: 2
                    }}
                  >
                    <MarkEmailReadIcon 
                      sx={{ 
                        fontSize: 40,
                        color: '#4CAF50',
                      }} 
                    />
                  </Box>

                  <Typography 
                    variant="h6" 
                    sx={{ 
                      fontWeight: 600,
                      color: '#2c3e50',
                      mb: 1,
                      direction: isRTL ? 'rtl' : 'ltr'
                    }}
                  >
                    {translation.noMessages}
                  </Typography>
                </Box>
              </Box>
            )}
          </>
        )}
      </Paper>
    </Container>
  );
}

export default AdminContacts;