import React, { useState } from "react";
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { storage } from '../config/firebase';

const ImageUploader = ({ onImageUpload, initialImage, location }) => {
  const [isUploading, setIsUploading] = useState(false);
  const [previewUrl, setPreviewUrl] = useState(initialImage || null);

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setPreviewUrl(URL.createObjectURL(file));
      setIsUploading(true);
      try {
        const imageRef = ref(storage, `${location}-images/${Date.now()}-${file.name}`);
        const uploadResult = await uploadBytes(imageRef, file);
        const imageUrl = await getDownloadURL(uploadResult.ref);
        onImageUpload(imageUrl);
      } catch (error) {
        console.error("Error uploading image:", error);
      } finally {
        setIsUploading(false);
      }
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
      <div style={{ 
        width: '100%',
        padding: '1rem',
        backgroundColor: 'white',
        borderRadius: '8px',
        border: '2px dashed #ccc',
        transition: 'border-color 0.3s',
        '&:hover': {
          borderColor: '#4CAF50'
        }
      }}>
        <div>
          {previewUrl ? (
            <div style={{ position: 'relative' }}>
              <img
                src={previewUrl}
                alt="Preview"
                style={{ 
                  width: '100%',
                  height: '300px',
                  objectFit: 'cover',
                  borderRadius: '4px'
                }}
              />
              <label style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                backgroundColor: 'rgba(0,0,0,0.5)',
                color: 'white',
                padding: '8px 16px',
                borderRadius: '20px',
                cursor: 'pointer',
                opacity: 0,
                transition: 'opacity 0.3s',
                '&:hover': {
                  opacity: 1
                }
              }}>
                تغيير الصورة
                <input
                  type="file"
                  style={{ display: 'none' }}
                  onChange={handleFileChange}
                  accept="image/*"
                />
              </label>
            </div>
          ) : (
            <label style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              height: '200px',
              cursor: 'pointer'
            }}>
              <div style={{ textAlign: 'center' }}>
                <div style={{ fontSize: '48px', color: '#ccc' }}>🖼️</div>
                <p style={{ marginTop: '8px', color: '#666' }}>انقر لرفع صورة</p>
                <p style={{ fontSize: '12px', color: '#999' }}>PNG, JPG حتى 10MB</p>
              </div>
              <input
                type="file"
                style={{ display: 'none' }}
                onChange={handleFileChange}
                accept="image/*"
              />
            </label>
          )}
        </div>
      </div>
      {isUploading && (
        <div style={{ marginTop: '1rem', display: 'flex', alignItems: 'center' }}>
          <div style={{ 
            width: '20px',
            height: '20px',
            border: '2px solid #4CAF50',
            borderTopColor: 'transparent',
            borderRadius: '50%',
            animation: 'spin 1s linear infinite',
            marginRight: '8px'
          }}></div>
          <span style={{ color: '#666' }}>جاري الرفع...</span>
        </div>
      )}
    </div>
  );
};

export default ImageUploader; 