import React, { useState, useEffect } from 'react';
import { 
  Container, 
  Typography, 
  Box, 
  Grid, 
  Card, 
  CardContent, 
  CardMedia, 
  CircularProgress
} from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { subscribeToResearches, incrementResearchClicks } from '../../services/researchService';
import { useTranslation } from 'react-i18next';

function Research() {
  const [research, setResearch] = useState([]);
  const [loading, setLoading] = useState(true);
  const { t} = useTranslation();
  useEffect(() => {
    // Set up real-time subscription
    const unsubscribe = subscribeToResearches((researchData) => {
      // Filter for visible research items
      const visibleResearch = researchData.filter(item => item.isVisible);
      setResearch(visibleResearch);
      setLoading(false);
    });

    // Cleanup subscription on component unmount
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, []);

  const tranlsation = {
    title: 'research.title',
    noResearch: 'research.noResearch',
    soon: 'research.soon',
  }

  const handleResearchClick = async (item) => {
    // Increment clicks when research is clicked
    await incrementResearchClicks(item.id);
    
    // Open the research link in a new tab
    if (item.link) {
      window.open(item.link, '_blank', 'noopener,noreferrer');
    }
  };

  // Helper function to detect Arabic text
  const isArabic = (text) => {
    const arabicPattern = /[\u0600-\u06FF]/;
    return arabicPattern.test(text);
  };

  return (
    <Container maxWidth="xl" sx={{ py: { xs: 4, md: 8 } }}>
      <Typography 
        variant="h2" 
        component="h1" 
        align="center"
        sx={{ 
          mb: { xs: 4, md: 6 },
          fontWeight: 600,
          color: '#2c3e50',
          fontSize: { xs: '1.75rem', sm: '2rem', md: '2.5rem' }
        }}
      >
        {t(tranlsation.title)}
      </Typography>

      {loading ? (
        <Box 
          sx={{ 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center', 
            minHeight: '300px'
          }}
        >
          <CircularProgress size={60} thickness={4} />
        </Box>
      ) : (
        <>
          <Grid container spacing={3}>
            {research.map((item) => (
              <Grid item xs={12} sm={6} md={4} key={item.id}>
                <Card 
                  component="a"
                  href={item.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => handleResearchClick(item)}
                  sx={{ 
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: '16px',
                    overflow: 'hidden',
                    transition: 'all 0.3s ease-in-out',
                    textDecoration: 'none',
                    cursor: 'pointer',
                    position: 'relative',
                    '&:hover': {
                      transform: 'translateY(-8px)',
                      boxShadow: '0 8px 30px rgba(0,0,0,0.12)',
                      '& .open-icon': {
                        opacity: 1,
                      }
                    }
                  }}
                >
                  <Box sx={{ position: 'relative', bgcolor: '#f8f9fa', p: 3 }}>
                    <CardMedia
                      component="img"
                      sx={{
                        height: 160,
                        objectFit: 'contain',
                        borderRadius: '12px',
                      }}
                      image={item.icon}
                      alt={item.title}
                    />
                    <Box
                      className="open-icon"
                      sx={{
                        position: 'absolute',
                        top: 16,
                        right: 16,
                        bgcolor: 'rgba(76, 175, 80, 0.9)',
                        borderRadius: '50%',
                        width: 36,
                        height: 36,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        opacity: 0,
                        transition: 'opacity 0.3s ease',
                        color: 'white',
                      }}
                    >
                      <OpenInNewIcon fontSize="small" />
                    </Box>
                  </Box>
                  <CardContent 
                    sx={{ 
                      flexGrow: 1, 
                      p: 3,
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      gap: 2,
                      '&:last-child': { pb: 3 }
                    }}
                  >
                    <Typography 
                      variant="h6" 
                      component="h2"
                      sx={{
                        fontWeight: 600,
                        fontSize: '1.1rem',
                        lineHeight: 1.4,
                        color: '#2c3e50',
                        textAlign: isArabic(item.title) ? 'right' : 'left',
                        direction: isArabic(item.title) ? 'rtl' : 'ltr'
                      }}
                    >
                      {item.title}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>

          {research.length === 0 && (
            <Box 
              sx={{ 
                textAlign: 'center', 
                mt: 8,
                p: 4,
                bgcolor: '#f8f9fa',
                borderRadius: '16px'
              }}
            >
              <Typography 
                variant="h6" 
                color="text.secondary"
                sx={{ fontWeight: 800 }}
              >
                {t(tranlsation.noResearch)}
              </Typography>
              <Typography 
                variant="body1" 
                color="text.secondary"
                sx={{ fontWeight: 500 }}
              >
                {t(tranlsation.soon)}
              </Typography>
            </Box>
          )}
        </>
      )}
    </Container>
  );
}

export default Research; 