import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
// import logo from '../Images/Informatics.jpg';
import logo2 from '../Images/informaticsRbg.png';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from './LanguageSwitcher';

function PublicNavbar() {
  const location = useLocation();
  const [isScrolled, setIsScrolled] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir() === 'rtl';

  const tranlsation = {
    home: 'navbar.home',
    blogs: 'navbar.blogs',
    research: 'navbar.research',
    services: 'navbar.services',
    contactMe: 'navbar.contactMe',
    bookNow: 'navbar.bookNow'
  }

  // const handleBookNow = () => {
  //   // window.location.href = '/consultation';
  // }

  const scrollToSection = (sectionId) => {
    if (location.pathname !== '/') {
      // If not on homepage, navigate and add event listener for after navigation
      localStorage.setItem('scrollTo', sectionId);
      window.location.href = '/';
    } else {
      // If on homepage, scroll directly
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  // Add effect to handle scroll after navigation
  useEffect(() => {
    const scrollTo = localStorage.getItem('scrollTo');
    if (scrollTo && location.pathname === '/') {
      const element = document.getElementById(scrollTo);
      if (element) {
        // Small delay to ensure page is loaded
        setTimeout(() => {
          element.scrollIntoView({ behavior: 'smooth' });
          localStorage.removeItem('scrollTo'); // Clean up
        }, 100);
      }
    }
  }, [location.pathname]);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 100);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const menuItems = [
    { text: t(tranlsation.home), path: '/' },
    { text: t(tranlsation.blogs), path: '/blogs' },
    { text: t(tranlsation.research), path: '/research' },
    { text: t(tranlsation.services), action: () => scrollToSection('services') },
    { text: t(tranlsation.contactMe), action: () => scrollToSection('contact') },
    // { text: t(tranlsation.bookNow), action: handleBookNow, color: true }
  ];

  const drawer = (
    <Box sx={{ 
      width: 250, 
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      py: 0
    }}>
      {/* Logo at the top */}
      <Box sx={{ 
        width: '100%', 
        display: 'flex', 
        justifyContent: 'center',
        px: 2,
      }}>
        <Box
          component={Link}
          to="/"
          onClick={handleDrawerToggle}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            textDecoration: 'none',
          }}
        >
          <Box
            component="img"
            src={logo2}
            alt="Logo"
            sx={{ 
              height: 125,
              width: '100%',
            }}
          />
        </Box>
      </Box>



      {/* Menu Items */}
      <List sx={{ width: '100%', flex: 1}}>
        {menuItems.map((item, index) => (
          <React.Fragment key={item.text}>
            <ListItem>
              <Button
                fullWidth
                onClick={() => {
                  if (item.action) {
                    item.action();
                  }
                  handleDrawerToggle();
                }}
                component={item.path ? Link : 'button'}
                to={item.path}
                sx={{
                  fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
                  color: (item.path && location.pathname === item.path) || item.color ? '#4CAF50' : 'text.primary',
                  fontSize: '1.1rem',
                  fontWeight: 500,
                  justifyContent: 'center',
                  textAlign: 'center',
                  '&:hover': {
                    color: '#4CAF50'
                  }
                }}
              >
                {item.text}
              </Button>
            </ListItem>
            {index < menuItems.length - 1 && (
              <Divider sx={{ my: 1 }} />
            )}
          </React.Fragment>
        ))}
      </List>


      {/* Consultation Button at the bottom */}
      <Box sx={{ px: 2, mt: 'auto' }}>
        <Button
          fullWidth
          variant="text"
          component={Link}
          to="/consultation"
          onClick={handleDrawerToggle}
          sx={{
            borderRadius: '8px',
            py: 1.5,
            color: '#4CAF50',
            fontSize: '1.1rem',
            whiteSpace: 'nowrap',
            transition: 'all 0.3s ease',
            mb: 2,
            backgroundColor: 'unset',
            '&:hover': {
              backgroundColor: 'rgba(76, 175, 80, 0.08)',
              transform: 'translateY(-1px)'
            }
          }}
        >
          {t(tranlsation.bookNow)}
        </Button>
      </Box>

        {/* Language Switcher - Added here */}
        <Box sx={{ 
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        mb: 2
      }}>
        <LanguageSwitcher />
      </Box>
    </Box>
  );

  return (
    <>
      <AppBar 
        position="fixed" 
        sx={{ 
          backgroundColor: 'white',
          boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
          transition: 'all 0.3s ease',
          direction: 'ltr',
          py: 1,
          width: '100vw',
          left: 0,
          right: 0,
          overflowX: 'hidden'
        }}
      >
        <Container 
          maxWidth={false}
          disableGutters
          sx={{ 
            px: { xs: 1, md: 2 },
            mx: 0,
            width: '100%'
          }}
        >
          <Toolbar 
            disableGutters 
            sx={{ 
              justifyContent: 'space-between',
              width: '100%',
              minHeight: { xs: '56px', md: '64px' },
              p: 0
            }}
          >
            {/* Menu Button */}
            <Box sx={{ width: { xs: '40px', md: '0px' }, pl: { xs: 2, sm: 3 } }}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ 
                  color: 'text.primary',
                  display: { xs: 'block', md: 'none' },
                  p: 0
                }}
              >
                <MenuIcon />
              </IconButton>
            </Box>

            {/* Language Switcher - Only show on larger screens */}
            <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center' }}>
              <LanguageSwitcher />
            </Box>

            <Box 
              sx={{ 
                display: { xs: 'none', md: 'flex' }, 
                gap: 4,
                justifyContent: 'center',
                flex: 1,
                flexDirection: isRTL ? 'row-reverse' : 'row' 
              }}
            >
              {menuItems.map((item) => (
                <Button
                  key={item.text}
                  onClick={item.action}
                  component={item.path ? Link : 'button'}
                  to={item.path}
                  sx={{ 
                    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
                    color: (item.path && location.pathname === item.path) || item.color ? '#4CAF50' : 'text.primary',
                    fontSize: '1.1rem',
                    fontWeight: 500,
                    px: 1,
                    position: 'relative',
                    '&::after': {
                      content: '""',
                      position: 'absolute',
                      bottom: 0,
                      left: '50%',
                      transform: 'translateX(-50%)',
                      width: (item.path && location.pathname === item.path) ? '100%' : '0%',
                      height: '2px',
                      backgroundColor: '#4CAF50',
                      transition: 'width 0.3s ease'
                    },
                    '&:hover::after': {
                      width: '100%'
                    },
                    '&:hover': {
                      color: '#4CAF50'
                    }
                  }}
                >
                  {item.text}
                </Button>
              ))}
            </Box>

            <Box
              component={Link}
              to="/"
              sx={{
                display: 'flex',
                alignItems: 'center',
                textDecoration: 'none',
                mr: { xs: 2, md: 3 }
              }}
            >
              <Typography
                variant="h6"
                sx={{ 
                  fontSize: { xs: '1rem', md: '1.25rem' },  // Reduced font size
                  fontWeight: 700,
                  color: '#36615d',
                  letterSpacing: '0.05em',
                  transition: 'all 0.3s ease'
                }}
              >
                INFORMATICS
              </Typography>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>

      <Box sx={{ height: { xs: '56px', md: '64px' } }} />

      <Drawer
        anchor="left"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true
        }}
        sx={{
          display: { xs: 'block', md: 'none' },
          '& .MuiDrawer-paper': { 
            boxSizing: 'border-box', 
            width: 250 
          },
        }}
      >
        {drawer}
      </Drawer>
    </>
  );
}

export default PublicNavbar; 