import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
// import LanguageIcon from '@mui/icons-material/Language';

function LanguageSwitcher() {
  const { i18n } = useTranslation();

  const toggleLanguage = () => {
    const newLang = i18n.language === 'ar' ? 'en' : 'ar';
    i18n.changeLanguage(newLang);
    document.dir = i18n.dir();
  };

  return (
    <Button
      onClick={toggleLanguage}
    //   startIcon={<LanguageIcon />}
      sx={{
        color: 'green',
        minWidth: 'auto',
        padding: '8px',
        // borderRadius: '50%',
        // backgroundColor: 'rgba(140, 211, 117, 0.74)',
        '&:hover': {
          backgroundColor: 'rgba(255, 255, 255, 0.2)'
        }
      }}
    >
      {i18n.language === 'ar' ? 'EN' : 'عربي'}
    </Button>
  );
}

export default LanguageSwitcher; 