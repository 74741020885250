import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { signOut } from 'firebase/auth';
import { auth } from '../config/firebase';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
// import logo from '../Images/Informatics.jpg';
// import logo2 from '../Images/informaticsRbg.png';
import LanguageSwitcher from './LanguageSwitcher';
import { useTranslation } from 'react-i18next';

function AdminNavbar() {
  const navigate = useNavigate();
  const location = useLocation();
  const [mobileOpen, setMobileOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir() === 'rtl';

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/login');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const menuItems = [
    { text: t('adminNavbar.blogs'), path: '/admin/blogs' },
    { text: t('adminNavbar.research'), path: '/admin/research' },
    { text: t('adminNavbar.opinions'), path: '/admin/opinions' },
    { text: t('adminNavbar.messages'), path: '/admin/contacts' },
  ];

  const drawer = (
    <Box sx={{ 
      width: 250, 
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      py: 0,
      bgcolor: 'white'
    }}>
      {/* Admin Panel Header */}
      <Box
        sx={{
          background: 'linear-gradient(135deg, #1e2a3b 0%, #2a5365 100%)',
          py: 3,
          px: 2,
          borderBottom: '1px solid rgba(255,255,255,0.1)',
          boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
        }}
      >
        <Typography
          variant="h6"
          component={Link}
          to="/admin/blogs"
          onClick={() => setMobileOpen(false)}
          sx={{
            color: '#fff',
            textDecoration: 'none',
            fontWeight: 700,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '1.5rem',
            '&:hover': {
              color: '#fff',
              opacity: 0.9,
              transition: 'all 0.3s ease'
            }
          }}
        >
          {t('adminNavbar.controlPanel')}
        </Typography>
      </Box>

      <List sx={{ 
        width: '100%', 
        flex: 1, 
        mt: 2,
      }}>
        {menuItems.map((item, index) => (
          <React.Fragment key={item.text}>
            <ListItem>
              <Button
                fullWidth
                component={Link}
                to={item.path}
                onClick={handleDrawerToggle}
                sx={{
                  fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
                  color: location.pathname === item.path ? '#4CAF50' : '#1e2a3b',
                  fontSize: '1.1rem',
                  fontWeight: location.pathname === item.path ? 700 : 500,
                  justifyContent: 'center',
                  textAlign: 'center',
                  direction: 'rtl',
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)'
                  }
                }}
              >
                {item.text}
              </Button>
            </ListItem>
            {index < menuItems.length - 1 && (
              <Divider sx={{ my: 1 }} />
            )}
          </React.Fragment>
        ))}
      </List>

      {/* Bottom section with Language Switcher and Logout */}
      <Box>
        <Divider sx={{ mb: 2 }} />
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'center',
          alignItems: 'center',
          gap: 2,
          mb: 2
        }}>
          <LanguageSwitcher />
          <IconButton
            onClick={() => {
              handleLogout();
              handleDrawerToggle();
            }}
            sx={{
              color: 'error.main',
              '&:hover': {
                backgroundColor: 'error.light',
              }
            }}
            aria-label="logout"
          >
            <LogoutIcon />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );

  return (
    <>
      <AppBar 
        position="fixed"
        sx={{ 
          backgroundColor: 'white', 
          boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
          transition: 'all 0.3s ease',
          direction: 'ltr',
          py: 1,
          width: '100vw',
          left: 0,
          right: 0,
          overflowX: 'hidden',
          zIndex: 1200
        }}
      >
        <Container maxWidth="lg">
          <Toolbar disableGutters sx={{ justifyContent: 'space-between' }}>
            {/* Title on the left for desktop */}
            <Typography
              variant="h6"
              component={Link}
              to="/admin/blogs"
              sx={{
                color: 'primary.main',
                fontWeight: 'bold',
                textDecoration: 'none',
                display: { xs: 'none', md: 'block' }
              }}
            >
              {t('adminNavbar.controlPanel')}
            </Typography>

            {/* Menu Button for mobile */}
            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ color: 'text.primary' }}
              >
                <MenuIcon />
              </IconButton>
            </Box>

            {/* Center content */}
            <Box sx={{ 
              flexGrow: 1, 
              display: { xs: 'none', md: 'flex' }, 
              justifyContent: 'center', 
              flexDirection: isRTL ? 'row-reverse' : 'row',
              gap: 2 
            }}>
              {menuItems.map((item) => (
                <Button 
                  key={item.text}
                  component={Link} 
                  to={item.path}
                  sx={{ 
                    fontSize: '1.1rem',
                    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
                    color: location.pathname === item.path ? '#4CAF50' : 'text.primary',
                    direction: 'rtl',
                    fontWeight: location.pathname === item.path ? 700 : 500,
                    position: 'relative',
                    transition: 'color 0.3s ease',
                    '&:hover': {
                      color: '#4CAF50',
                    },
                    '&:after': {
                      content: '""',
                      position: 'absolute',
                      bottom: -1,
                      left: 0,
                      right: 0,
                      height: 2,
                      backgroundColor: '#4CAF50',
                      borderRadius: '2px 2px 0 0',
                      transform: location.pathname === item.path ? 'scaleX(1)' : 'scaleX(0)',
                      transition: 'transform 0.3s ease',
                    },
                    '&:hover:after': {
                      transform: 'scaleX(1)'
                    }
                  }}
                >
                  {item.text}
                </Button>
              ))}
            </Box>

            {/* Right section with Language Switcher and Logout for desktop */}
            <Box sx={{ 
              display: { xs: 'none', md: 'flex' }, 
              alignItems: 'center',
              gap: 2
            }}>
              <LanguageSwitcher />
              <IconButton
                onClick={handleLogout}
                sx={{
                  color: 'error.main',
                  '&:hover': {
                    bgcolor: 'rgba(231, 76, 60, 0.1)'
                  }
                }}
                aria-label="logout"
              >
                <LogoutIcon />
              </IconButton>
            </Box>

            {/* Mobile title */}
            <Typography
              variant="h6"
              sx={{ 
                display: { xs: 'block', md: 'none' },
                color: '#2a5365',
                fontWeight: 600,
                letterSpacing: 1,
                fontSize: '1rem'
              }}
            >
              INFORMATICS
            </Typography>
          </Toolbar>
        </Container>
      </AppBar>

      {/* Add spacing to prevent content from hiding under fixed AppBar */}
      <Box sx={{ height: { xs: '72px', md: '80px' } }} />

      <Drawer
        anchor="left"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true
        }}
        sx={{
          display: { xs: 'block', md: 'none' },
          '& .MuiDrawer-paper': { 
            boxSizing: 'border-box', 
            width: 250,
            height: '100%',
            zIndex: 1300
          },
          '& .MuiBackdrop-root': {
            zIndex: 1250
          }
        }}
      >
        {drawer}
      </Drawer>
    </>
  );
}

export default AdminNavbar; 