import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  // Your Firebase configuration here
  apiKey: "AIzaSyCa7jT_awd8GaQE5J-dyvKHupuNZHY29xY",
  authDomain: "consultation-8af9e.firebaseapp.com",
  projectId: "consultation-8af9e",
  storageBucket: "consultation-8af9e.firebasestorage.app",
  messagingSenderId: "426258310438",
  appId: "1:426258310438:web:c5974fd2453ec689db8de7",
  measurementId: "G-29R8HX1YCC"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);