import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../config/firebase';
import { signInWithEmailAndPassword } from 'firebase/auth';
import {
  Container,
  Box,
  TextField,
  Button,
  Typography,
  Alert
} from '@mui/material';

function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/admin/blogs');
    } catch (error) {
      // Convert Firebase auth errors to user-friendly Arabic messages
      switch (error.code) {
        case 'auth/invalid-credential':
        case 'auth/user-not-found':
        case 'auth/wrong-password':
          setError('البريد الإلكتروني أو كلمة المرور غير صحيحة');
          break;
        case 'auth/too-many-requests':
          setError('تم تجاوز عدد المحاولات المسموح بها. الرجاء المحاولة لاحقاً');
          break;
        default:
          setError('حدث خطأ. الرجاء المحاولة مرة أخرى');
      }
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          direction: 'rtl'
        }}
      >
        <Typography component="h1" variant="h5">
          تسجيل الدخول
        </Typography>
        {error && <Alert severity="error" sx={{ mt: 2, width: '100%', direction: 'rtl' }}>{error}</Alert>}
        <Box 
          component="form" 
          onSubmit={handleSubmit} 
          sx={{
            mt: 1,
            width: '100%',
            '& .MuiTextField-root': {
              '& .MuiOutlinedInput-root': {
                '&:hover fieldset': {
                  borderColor: '#1a237e',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#1a237e',
                },
                '& input': {
                  textAlign: 'right',
                },
                '& fieldset': {
                  textAlign: 'right',
                  '& legend': {
                    marginRight: '8px',
                    marginLeft: 'auto',
                  }
                }
              },
              '& .MuiFormLabel-root': {
                right: '24px !important',
                left: 'auto !important',
                transformOrigin: 'top right !important',
                display: 'inline-flex !important',
                flexDirection: 'row !important',
                '&.MuiInputLabel-shrink': {
                  transform: 'translate(0, -9px) scale(0.75) !important',
                }
              },
              '& .MuiFormLabel-asterisk': {
                marginLeft: '0px !important',
                marginRight: '4px !important'
              }
            }
          }}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            label="البريد الإلكتروني"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            label="كلمة المرور"
            type="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            دخول
          </Button>
        </Box>
      </Box>
    </Container>
  );
}

export default Login; 