import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import SearchIcon from '@mui/icons-material/Search';
import { 
  Container, 
  Typography, 
  Paper, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow,
  IconButton,
  Button,
  TextField,
  Box,
  Switch,
  InputBase,
  Grid,
  FormControlLabel,
  CircularProgress
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import ImageUploader from '../../components/ImageUploader';
import { addResearch, getResearch, updateResearch, deleteResearch } from '../../services/researchService';
import CloseIcon from '@mui/icons-material/Close';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Swal from 'sweetalert2';
import TableSortLabel from '@mui/material/TableSortLabel';
import { useTranslation } from 'react-i18next';

function AdminResearch() {
  const { user } = useAuth();
  const [searchTerm, setSearchTerm] = useState('');
  const [research, setResearch] = useState([]);
  const [open, setOpen] = useState(false);
  const [editingResearch, setEditingResearch] = useState(null);
  const [formData, setFormData] = useState({
    title: '',
    icon: '',
    url: '',
    isVisible: true,
    createdAt: null,
    createdBy: null
  });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [sortField, setSortField] = useState('createdAt');
  const [sortDirection, setSortDirection] = useState('desc');
  const [loading, setLoading] = useState(true);
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir() === 'rtl';

  const translation = {
    title: t('adminResearch.title'),
    addResearch: t('adminResearch.addResearch'),
    cancel: t('adminResearch.cancel'),
    editResearch: t('adminResearch.editResearch'),
    deleteResearch: t('adminResearch.deleteResearch'),
    updateResearch: t('adminResearch.updateResearch'),
    researchTitle: t('adminResearch.researchTitle'),
    icon: t('adminResearch.icon'),
    link: t('adminResearch.link'),
    clickCount: t('adminResearch.clickCount'),
    status: t('adminResearch.status'),
    actions: t('adminResearch.actions'),
    noResearch: t('adminResearch.noResearch'), 
    search: t('adminResearch.search'),
    saving: t('adminResearch.saving'),
    deleting: t('adminResearch.deleting'),
    sucess: {
      update: t('adminResearch.success.update'),
      add: t('adminResearch.success.add'),
      delete: t('adminResearch.success.delete')
    },
    error: {
      title: t('adminResearch.error.title'),
      save: t('adminResearch.error.save'),
      update: t('adminResearch.error.update'),
      add: t('adminResearch.error.add'),
      delete: t('adminResearch.error.delete')
    },
    delete: {
      title: t('adminResearch.delete.title'),
      text: t('adminResearch.delete.text'),
      confirmButtonText: t('adminResearch.delete.confirmButtonText'),
      cancelButtonText: t('adminResearch.delete.cancelButtonText')
    }
  }

  useEffect(() => {
    fetchResearch();
  }, []);

  const fetchResearch = async () => {
    try {
      setLoading(true);
      const data = await getResearch();
      setResearch(data);
    } catch (error) {
      console.error('Error fetching research:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleOpen = async(research = null) => {
    if (research) {
      setEditingResearch(research);
      setFormData({
        title: research.title,
        icon: research.icon,
        url: research.url,
        isVisible: research.isVisible ?? true,
        createdAt: research.createdAt,
        createdBy: research.createdBy
      });
    } else {
      setEditingResearch(null);
      setFormData({ 
        title: '', 
        icon: '', 
        url: '', 
        isVisible: true,
        createdAt: null,
        createdBy: null
      });
    }
    await setOpen(true);
    document.getElementById('add-edit-form').scrollIntoView({
      behavior: 'smooth',
      block: 'center' // This will align the element in the center of viewport
    });
  };

  const handleClose = () => {
    setOpen(false);
    setEditingResearch(null);
    setFormData({ 
      title: '', 
      icon: '', 
      url: '', 
      isVisible: true,
      createdAt: null,
      createdBy: null
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      const newData = {
        ...formData,
        createdAt: editingResearch ? formData.createdAt : new Date().toISOString(),
        createdBy: editingResearch ? formData.createdBy : user.uid
      };

      // Show loading state
      Swal.fire({
        title: translation.saving,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        }
      });
      
      if (editingResearch) {
        await updateResearch(editingResearch.id, newData);
        Swal.fire({
          icon: 'success',
          title: translation.sucess.update,
          showConfirmButton: false,
          timer: 1500
        });
      } else {
        await addResearch(newData);
        Swal.fire({
          icon: 'success',
          title: translation.sucess.add,
          showConfirmButton: false,
          timer: 1500
        });
      }
      
      handleClose();
      fetchResearch();
    } catch (error) {
      console.error('Error saving research:', error);
      Swal.fire({
        icon: 'error',
        title: translation.error.title,
        text: translation.error.save,
        confirmButtonText: translation.error.confirmButtonText
      });
    }
  };

  const handleDelete = async (id) => {
    const result = await Swal.fire({
      title: translation.delete.title,
      text: translation.delete.text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: translation.delete.confirmButtonText,
      cancelButtonText: translation.delete.cancelButtonText
    });

    if (result.isConfirmed) {
      try {
        Swal.fire({
          title: translation.deleting,
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          }
        });

        await deleteResearch(id, user.uid);
        
        Swal.fire({
          icon: 'success',
          title: translation.sucess.delete,
          showConfirmButton: false,
          timer: 1500
        });

        fetchResearch();
      } catch (error) {
        console.error('Error deleting research:', error);
        Swal.fire({
          icon: 'error',
          title: translation.error.title,
          text: translation.error.delete,
          confirmButtonText: translation.error.confirmButtonText
        });
      }
    }
  };

  const handleVisibilityToggle = async (id, currentVisibility) => {
    try {
      await updateResearch(id, { isVisible: !currentVisibility });
      setResearch(research.map(item => 
        item.id === id ? { ...item, isVisible: !currentVisibility } : item
      ));
    } catch (error) {
      console.error('Error updating visibility:', error);
      Swal.fire({
        icon: 'error',
        title: translation.error.title,
        text: translation.error.update,
        confirmButtonText: translation.error.confirmButtonText,
        customClass: {
          popup: 'swal2-rtl'
        }
      });
    }
  };

  const handleSortClick = (field) => {
    if (field === sortField) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
  };

  const filteredAndSortedResearch = research
    .filter(item => 
      item.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.url.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .sort((a, b) => {
      if (sortField === 'createdAt') {
        return sortDirection === 'asc' 
          ? new Date(a.createdAt) - new Date(b.createdAt)
          : new Date(b.createdAt) - new Date(a.createdAt);
      }
      if (sortField === 'clicks') {
        const aClicks = a.clicks || 0;
        const bClicks = b.clicks || 0;
        return sortDirection === 'asc' ? aClicks - bClicks : bClicks - aClicks;
      }
      return sortDirection === 'asc'
        ? a[sortField]?.localeCompare(b[sortField])
        : b[sortField]?.localeCompare(a[sortField]);
    });

  const isArabic = (text) => {
    const arabicPattern = /[\u0600-\u06FF]/;
    return arabicPattern.test(text);
  };

  const styles = `
    @keyframes spin {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
    
    .swal2-rtl {
      direction: rtl;
    }
  `;

  const styleSheet = document.createElement("style");
  styleSheet.innerText = styles;
  document.head.appendChild(styleSheet);

  const AddEditForm = (
    <Paper
      id="add-edit-form"
      elevation={2}
      sx={{
        p: { xs: 2, sm: 3 },
        mb: { xs: 4, sm: 5, md: 6 },
        mt: { xs: 3, sm: 4 },
        borderRadius: '12px',
        maxWidth: '1600px',
        margin: '0 auto',
        bgcolor: '#fff',
        direction: isRTL ? 'rtl' : 'ltr',
      }}
    >
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'center',
        alignItems: 'center',
        mb: 3,
        position: 'relative'
      }}>
        <Typography 
          variant="h6" 
          sx={{ 
            fontWeight: 600,
            color: '#2c3e50',
            fontFamily: 'Tajawal, sans-serif',
            textAlign: 'center'
          }}
        >
          {editingResearch ? translation.editResearch : translation.addResearch}
        </Typography>
        <IconButton 
          onClick={handleClose}
          sx={{
            position: 'absolute',
            left: 0,
            color: 'red'
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>

      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              multiline
              label={translation.researchTitle}
              value={formData.title}
              onChange={(e) => setFormData({ ...formData, title: e.target.value })}
              required
              InputProps={{
                sx: { textAlign: 'right' }
              }}
              sx={{
                '& .MuiInputLabel-root': {
                  right: isRTL ? '23px' : 'auto',
                  left: isRTL ? 'auto' : '8px',
                  transformOrigin: 'top right',
                  '&.MuiInputLabel-shrink': {
                    transform: 'translate(0, -9px) scale(0.75)',
                  }
                },
                '& .MuiOutlinedInput-root': {
                  '& input': {
                    textAlign: 'left',
                  },
                  '& fieldset': {
                    textAlign: 'left',
                  },
                  '& legend': {
                    marginRight: isRTL ? '8px' : 'auto',
                    marginLeft: isRTL ? 'auto' : '4px',
                  }
                },
                fontFamily: 'Tajawal, sans-serif',
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label={translation.link}
              value={formData.url}
              onChange={(e) => setFormData({ ...formData, url: e.target.value })}
              required
              InputProps={{
                sx: { textAlign: 'left' }
              }}
              sx={{
                '& .MuiInputLabel-root': {
                  right: isRTL ? '23px' : 'auto',
                  left: isRTL ? 'auto' : '8px',
                  transformOrigin: 'top right',
                  '&.MuiInputLabel-shrink': {
                    transform: 'translate(0, -9px) scale(0.75)',
                  }
                },
                '& .MuiOutlinedInput-root': {
                  '& input': {
                    textAlign: 'left',
                  },
                  '& fieldset': {
                    textAlign: 'left',
                  },
                  '& legend': {
                    marginRight: isRTL ? '8px' : 'auto',
                    marginLeft: isRTL ? 'auto' : '4px',
                  }
                },
                fontFamily: 'Tajawal, sans-serif',
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{ mb: 2 }}>
              <Typography 
                variant="subtitle1" 
                gutterBottom
                sx={{
                  fontFamily: 'Tajawal, sans-serif',
                  fontSize: '1.1rem',
                  fontWeight: 500,
                  color: '#2c3e50',
                  mb: 1,
                  textAlign: 'center'
                }}
              >
                {translation.icon}
              </Typography>
              <Box
                sx={{
                  border: '2px dashed #ccc',
                  borderRadius: '8px',
                  p: 2,
                  minHeight: '120px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%'
                }}
              >
                <ImageUploader
                  onImageUpload={(imageUrl) => setFormData({ ...formData, icon: imageUrl })}
                  initialImage={formData.icon}
                  location="research"
                  // sx={{ width: 'inherit'}}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} sx={{ 
            display: 'flex', 
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'row' 
          }}>
            <FormControlLabel
              control={
                <Switch
                  checked={formData.isVisible}
                  onChange={(e) => setFormData({ ...formData, isVisible: e.target.checked })}
                  color="primary"
                />
              }
              label={translation.status}
              labelPlacement="start"
              sx={{
                '& .MuiFormControlLabel-label': {
                  fontFamily: 'Tajawal, sans-serif',
                }
              }}
            />
          </Grid>
          <Grid item xs={12} sx={{ 
            display: 'flex', 
            justifyContent: 'space-between',
            gap: 2,
            mt: 2 
          }}>
            <Button 
              onClick={handleClose}
              variant="outlined"
              sx={{
                fontFamily: 'Tajawal, sans-serif',
                color: '#64748b',
                borderColor: '#64748b',
                '&:hover': {
                  borderColor: '#475569',
                  bgcolor: 'transparent'
                },
                '& .MuiButton-endIcon': {
                  margin: '0px'
                }
              }}
              endIcon={<CloseIcon />}
            >
              {translation.cancel}
            </Button>
            <Button 
              type="submit" 
              variant="contained"
              endIcon={editingResearch ? <EditIcon /> : <AddIcon />}
              sx={{
                fontFamily: 'Tajawal, sans-serif',
                bgcolor: '#4CAF50',
                gap: 1,
                '&:hover': {
                  bgcolor: '#388E3C'
                },
                '& .MuiButton-endIcon': {
                  margin: '0px'
                }
              }}
            >
              {editingResearch ? translation.updateResearch : translation.addResearch}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );

  return (
    <Container 
      maxWidth={false} 
      sx={{ 
        mt: 4, 
        mb: 4,
        px: { xs: 2, sm: 4, md: 6, lg: 8 },
        maxWidth: '1800px',
        margin: '0 auto',
        direction: isRTL ? 'rtl' : 'ltr',
        padding:"0px 24px !important"
      }}
    >
      <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column',
        alignItems: 'center',
        mb: 3,
      }}>
        <Typography 
          variant="h4" 
          component="h1" 
          sx={{ 
            fontWeight: 600,
            color: '#2c3e50',
            fontSize: { xs: '1.5rem', sm: '1.8rem', md: '2rem' },
            textAlign: 'center',
            my: 3
          }}
        >
          {translation.title}
        </Typography>

        <Box sx={{ 
          width: '100%', 
          maxWidth: '1600px',
          display: 'flex', 
          alignItems: 'center',
          gap: 2,
          px: { xs: 2, sm: 3, md: 0 }
        }}>
          <Box sx={{ 
            display: 'flex',
            flex: 1,
            position: 'relative',
            bgcolor: '#f8f9fa',
            borderRadius: '12px',
            border: '1px solid #e0e0e0',
            height: '50px'
          }}>
            <SearchIcon sx={{ 
              position: 'absolute',
              left: isRTL ? 'auto' : 16,
              right: isRTL ? 16 : 'auto',
              top: '50%',
              transform: 'translateY(-50%)',
              color: '#94a3b8',
              fontSize: '1.5rem',
            }} />
            <InputBase
              placeholder={translation.search + "..."}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              sx={{
                flex: 1,
                pl: isRTL ? 3 : 6,
                pr: isRTL ? 6 : 3,
                fontSize: '1.1rem',
                height: '100%',
                direction: isRTL ? 'rtl' : 'ltr',
              }}
            />
          </Box>
          <IconButton
            onClick={() => handleOpen()}
            sx={{ 
              color: '#4CAF50',
              p: 1.5,
              '&:hover': { 
                color: '#45a049',
                transform: 'scale(1.1)',
              },
              transition: 'all 0.2s ease'
            }}
          >
            <AddIcon sx={{ fontSize: '2rem' }} />
          </IconButton>
        </Box>
      </Box>

      {open && AddEditForm}

      <TableContainer 
        component={Paper} 
        sx={{ 
          boxShadow: '0 2px 12px rgba(0,0,0,0.08)',
          borderRadius: '12px',
          overflow: 'hidden',
          width: '100%',
          maxWidth: '1600px',
          margin: '0 auto',
          mt: open ? { xs: 2, sm: 3 } : 0,
          '& .MuiTableCell-root': {
            py: 2.5,
            px: 3,
            fontSize: '1rem'
          },
          position: 'relative',
          minHeight: '200px'
        }}
      >
        {loading ? (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              bgcolor: 'rgba(255, 255, 255, 0.8)',
              zIndex: 1,
            }}
          >
            <CircularProgress 
              size={60}
              thickness={4}
              sx={{
                color: '#4CAF50',
              }}
            />
          </Box>
        ) : null}
        {isMobile ? (
          <Box sx={{ p: 2 }}>
            {filteredAndSortedResearch.map((item) => (
              <Paper
                key={item.id}
                sx={{
                  p: 2,
                  mb: 2,
                  borderRadius: '12px',
                  boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                  overflow: 'hidden'
                }}
              >
                {/* Image Container */}
                <Box sx={{ 
                  width: '100%',
                  height: '140px',
                  mb: 2,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  bgcolor: '#f8f9fa',
                  borderRadius: '8px',
                  overflow: 'hidden'
                }}>
                  {item.icon ? (
                    <Box 
                      component="img"
                      src={item.icon}
                      alt={item.title}
                      sx={{ 
                        height: '100%',
                        width: '100%',
                        objectFit: 'contain',
                        p: 2
                      }}
                    />
                  ) : (
                    <Box 
                      sx={{ 
                        height: '100%',
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: '#94a3b8'
                      }}
                    >
                      لا توجد صورة
                    </Box>
                  )}
                </Box>

                {/* Content */}
                <Box>
                  <Typography 
                    sx={{ 
                      fontWeight: 600,
                      fontSize: '1.1rem',
                      mb: 2,
                      textAlign: "justify",
                      direction: isArabic(item.title) ? 'rtl' : 'ltr',
                      textJustify: 'inter-word', // Improves text justification
                      wordSpacing: '0.05em', // Adds slight spacing between words
                      hyphens: 'auto', // Enables hyphenation for better text flow
                      WebkitHyphens: 'auto',
                      MsHyphens: 'auto'
                    }}
                  >
                    {item.title}
                  </Typography>

                  <Typography 
                    variant="body2" 
                    color="text.secondary"
                    sx={{ 
                      mb: 2,
                      wordBreak: 'break-all',
                      direction: 'ltr',
                      fontSize: '0.9rem',
                      textAlign: 'left',
                      fontFamily: 'monospace',
                      bgcolor: '#f8f9fa',
                      p: 1,
                      borderRadius: '4px',
                      overflowWrap: 'break-word'
                    }}
                  >
                    {item.url}
                  </Typography>

                  <Box sx={{ 
                    display: 'flex', 
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    borderTop: '1px solid #e2e8f0',
                    pt: 2,
                    mt: 2
                  }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      <Typography 
                        variant="caption" 
                        color="text.secondary"
                      >
                        {new Date(item.createdAt).toLocaleString('en-US', {
                          year: 'numeric',
                          month: 'short',
                          day: 'numeric'
                        })}
                      </Typography>
                      <Typography 
                        variant="caption" 
                        sx={{ 
                          color: 'text.secondary',
                          display: 'flex',
                          alignItems: 'center',
                          gap: 0.5
                        }}
                      >
                        {translation.clickCount}: {item.clicks || 0}
                      </Typography>
                      <Switch
                        checked={item.isVisible}
                        onChange={() => handleVisibilityToggle(item.id, item.isVisible)}
                        color="primary"
                        size="small"
                      />
                    </Box>

                    <Box sx={{ display: 'flex', gap: 1 }}>
                      <IconButton 
                        onClick={() => handleOpen(item)}
                        size="small"
                        sx={{ 
                          color: '#3498db',
                          bgcolor: 'rgba(52, 152, 219, 0.1)',
                          '&:hover': { bgcolor: 'rgba(52, 152, 219, 0.2)' }
                        }}
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                      <IconButton 
                        onClick={() => handleDelete(item.id)}
                        size="small"
                        sx={{ 
                          color: '#e74c3c',
                          bgcolor: 'rgba(231, 76, 60, 0.1)',
                          '&:hover': { bgcolor: 'rgba(231, 76, 60, 0.2)' }
                        }}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </Box>
                  </Box>
                </Box>
              </Paper>
            ))}
          </Box>
        ) : (
          // Desktop view using table
          <Table dir={isRTL ? 'rtl' : 'ltr'}>
            <TableHead>
              <TableRow sx={{ bgcolor: '#f8f9fa' }}>
                <TableCell 
                  align="right" 
                  sx={{ 
                    fontWeight: 600,
                    color: '#2c3e50',
                    fontSize: '0.95rem',
                    borderBottom: '2px solid #e9ecef',
                    width: '25%',
                    textAlign: 'center'
                  }}
                >
                  <TableSortLabel
                    active={sortField === 'title'}
                    direction={sortField === 'title' ? sortDirection : 'asc'}
                    onClick={() => handleSortClick('title')}
                  >
                    {translation.title}
                  </TableSortLabel>
                </TableCell>
                <TableCell 
                  align="center"
                  sx={{ 
                    fontWeight: 600,
                    color: '#2c3e50',
                    fontSize: '0.95rem',
                    borderBottom: '2px solid #e9ecef',
                    width: '15%',
                    textAlign: 'center'
                  }}
                >
                  {translation.icon}
                </TableCell>
                <TableCell 
                  align="right"
                  sx={{ 
                    fontWeight: 600,
                    color: '#2c3e50',
                    fontSize: '0.95rem',
                    borderBottom: '2px solid #e9ecef',
                    width: '30%',
                    textAlign: 'center'
                  }}
                >
                  <TableSortLabel
                    active={sortField === 'url'}
                    direction={sortField === 'url' ? sortDirection : 'asc'}
                    onClick={() => handleSortClick('url')}
                  >
                  {translation.link}
                  </TableSortLabel>
                </TableCell>
                <TableCell 
                  align="center"
                  sx={{ 
                    fontWeight: 600,
                    color: '#2c3e50',
                    fontSize: '0.95rem',
                    borderBottom: '2px solid #e9ecef',
                    width: '10%',
                    textAlign: 'center'
                  }}
                >
                  <TableSortLabel
                    active={sortField === 'clicks'}
                    direction={sortField === 'clicks' ? sortDirection : 'asc'}
                    onClick={() => handleSortClick('clicks')}
                  >
                  {translation.clickCount}
                  </TableSortLabel>
                </TableCell>
                <TableCell 
                  align="center"
                  sx={{ 
                    fontWeight: 600,
                    color: '#2c3e50',
                    fontSize: '0.95rem',
                    borderBottom: '2px solid #e9ecef',
                    width: '7%',
                    textAlign: 'center'
                  }}
                >
                  {translation.status}
                </TableCell>
                <TableCell 
                  align="center"
                  sx={{ 
                    fontWeight: 600,
                    color: '#2c3e50',
                    fontSize: '0.95rem',
                    borderBottom: '2px solid #e9ecef',
                    width: '8%',
                    textAlign: 'center'
                  }}
                >
                  {translation.actions}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredAndSortedResearch.map((item) => (
                <TableRow 
                  key={item.id}
                  sx={{ 
                    '&:hover': { 
                      bgcolor: '#f8f9fa',
                      transition: 'background-color 0.3s ease'
                    }
                  }}
                >
                  <TableCell 
                    sx={{ 
                      color: '#34495e',
                      fontSize: '0.9rem',
                      maxWidth: '250px',
                      position: 'relative',
                      textAlign: isArabic(item.title) ? 'right' : 'left',
                      textJustify: 'inter-word',
                      direction: isArabic(item.title) ? 'rtl' : 'ltr',
                      '& .content': {
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        transition: 'all 0.3s ease',
                        textAlign: 'justify',
                        hyphens: 'auto',
                        WebkitHyphens: 'auto',
                        MsHyphens: 'auto',
                        wordSpacing: '0.05em'
                      },
                      '&:hover .content': {
                        whiteSpace: 'wrap',
                        maxWidth: '500px'
                      }
                    }}
                  >
                    <div className="content">{item.title}</div>
                  </TableCell>
                  <TableCell align="center">
                    {item.icon && (
                      <Box 
                        component="img"
                        src={item.icon}
                        alt={item.title}
                        sx={{ 
                          width: 45,
                          height: 45,
                          objectFit: 'contain',
                          borderRadius: '8px',
                          boxShadow: '0 2px 8px rgba(0,0,0,0.1)'
                        }}
                      />
                    )}
                  </TableCell>
                  <TableCell 
                    sx={{ 
                      color: '#34495e',
                      fontSize: '0.9rem',
                      maxWidth: '300px',
                      position: 'relative',
                      '& .content': {
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        transition: 'all 0.3s ease',
                      },
                      '&:hover .content': {
                        whiteSpace: 'wrap',
                        maxWidth: '500px'
                      }
                    }}
                  >
                    <div className="content">{item.url}</div>
                  </TableCell>
                  <TableCell 
                    align="center"
                    sx={{ 
                      color: '#34495e',
                      fontSize: '0.9rem'
                    }}
                  >
                    {item.clicks || 0}
                  </TableCell>
                  <TableCell align="center">
                    <Switch
                      checked={item.isVisible}
                      onChange={() => handleVisibilityToggle(item.id, item.isVisible)}
                      color="primary"
                    />
                  </TableCell>
                  <TableCell align="center">
                    <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1 }}>
                      <IconButton 
                        onClick={() => handleOpen(item)} 
                        sx={{ 
                          color: '#3498db',
                          '&:hover': { 
                            color: '#2980b9',
                          }
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton 
                        onClick={() => handleDelete(item.id)}
                        sx={{ 
                          color: '#e74c3c',
                          backgroundColor: 'unset',
                          '&:hover': { 
                            color: '#c0392b',
                            bgcolor: 'rgba(231, 76, 60, 0.1)'
                          }
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
    </Container>
  );
}

export default AdminResearch; 