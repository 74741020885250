import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Typography, Box, CircularProgress } from '@mui/material';
import { subscribeToBlogById, incrementBlogViews } from '../../services/blogService';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css'; // Using bubble theme for read-only content

// Add this helper function at the top of the component
function isRTLText(text) {
  // Check for RTL characters (Arabic, Hebrew, etc.)
  const rtlRegex = /[\u0591-\u07FF\u200F\u202B\u202E\uFB1D-\uFDFD\uFE70-\uFEFC]/;
  return rtlRegex.test(text);
}

function BlogPost() {
  const { id } = useParams();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [countdown, setCountdown] = useState(3); // Add countdown state
  const navigate = useNavigate();
  const viewCountedRef = useRef(false);

  useEffect(() => {
    const unsubscribe = subscribeToBlogById(id, (data, error) => {
      if (error) {
        console.error('Error fetching post:', error);
        setLoading(false);
        return;
      }
      setPost(data);
      setLoading(false);

      // Increment views only once per session
      if (data && !viewCountedRef.current) {
        incrementBlogViews(id);
        viewCountedRef.current = true;
      }
    });

    return () => {
      unsubscribe();
    };
  }, [id]);

  // Add countdown effect when post is not found
  useEffect(() => {
    if (!loading && !post) {
      const timer = setInterval(() => {
        setCountdown((prev) => {
          if (prev <= 1) {
            clearInterval(timer);
            navigate('/blogs');
            return 0;
          }
          return prev - 1;
        });
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [loading, post, navigate]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" mt={4}>
        <CircularProgress />
      </Box>
    );
  }

  if (!post) {
    return (
      <Box 
        display="flex" 
        flexDirection="column" 
        alignItems="center"
        justifyContent="center"
        minHeight="100vh"
        px={2}
      >
        <Typography 
          variant="h6" 
          align="center" 
          sx={{ 
            mb: 2,
            fontSize: { xs: '1.1rem', sm: '1.25rem' },
            maxWidth: '600px'
          }}
        >
          هذه المدونة غير موجودة سيتم توجيهك الى القائمة الرئيسية خلال {countdown} ثوان
        </Typography>
        <CircularProgress size={30} />
      </Box>
    );
  }

  return (
    <Container maxWidth="lg">
      <Box sx={{ 
        mt: { xs: 4, sm: 6, md: 10 }, // Responsive top margin
        mb: { xs: 4, sm: 6 }, // Responsive bottom margin
        px: { xs: 2, sm: 3 } // Responsive horizontal padding
      }}>
        {post.imageUrl && (
          <Box
            component="img"
            sx={{
              width: '100%',
              height: { xs: '300px', sm: '400px', md: '500px' }, // Responsive height
              objectFit: 'contain',
              borderRadius: 2,
              mb: 3,
            }}
            src={post.imageUrl}
            alt={post.title}
          />
        )}
        <Box sx={{ width: '100%' }}>
          <Typography 
            variant="h2" 
            component="h1" 
            gutterBottom
            sx={{
              fontWeight: 700,
              mb: 3,
              textAlign: 'center',
              fontSize: { xs: '1.75rem', sm: '2.5rem', md: '3.75rem' }, // Responsive font size
              wordBreak: 'break-word' // Prevent text overflow on small screens
            }}
          >
            {post.title}
          </Typography>
          
          <Box sx={{ 
            direction: isRTLText(post.content) ? 'rtl' : 'ltr',
            textAlign: isRTLText(post.content) ? 'right' : 'left',
            '& .ql-editor': {
              padding: '0 !important',
              textAlign: 'inherit',
              fontSize: { xs: '1rem', sm: '1.1rem' }, // Responsive content font size
              '& p': {
                textAlign: 'inherit',
                lineHeight: 1.6 // Improve readability
              },
              '& ul, & ol': {
                paddingRight: isRTLText(post.content) ? '2em' : '0',
                paddingLeft: isRTLText(post.content) ? '0' : '2em',
                margin: '0',
              },
              '& li': {
                display: 'flex',
                flexDirection: isRTLText(post.content) ? 'row-reverse' : 'row',
                justifyContent: isRTLText(post.content) ? 'flex-end' : 'flex-start',
                position: 'relative',
                '&::before': {
                  position: 'absolute',
                  [isRTLText(post.content) ? 'right' : 'left']: '-2em',
                  marginLeft: isRTLText(post.content) ? '0.5em' : '0',
                  marginRight: isRTLText(post.content) ? '0' : '0.5em',
                  textAlign: isRTLText(post.content) ? 'right' : 'left',
                }
              },
              '& ul > li::before': {
                content: '"•"',
              },
              '& ol': {
                counterReset: 'list-counter',
              },
              '& ol > li': {
                counterIncrement: 'list-counter',
              },
              '& ol > li::before': {
                content: 'counter(list-counter) "."',
              }
            }
          }}>
            <ReactQuill 
              value={post.content}
              readOnly={true}
              theme="bubble"
              modules={{ toolbar: false }}
            />
          </Box>
        </Box>
      </Box>
    </Container>
  );
}

export default BlogPost; 