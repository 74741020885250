import React, { useState } from 'react';
import { Container, Box, Typography, TextField, Button, Alert, Paper } from '@mui/material';
import { createOpinion } from '../../services/opinionService';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';

function AddOpinion() {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir() === 'rtl';
  const [opinion, setOpinion] = useState({
    name: '',
    position: '',
    message: '',
    email: ''
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

 const translation = {
  title: t('addOpinion.title'),
  name: t('addOpinion.name'),
  position: t('addOpinion.position'),
  email: t('addOpinion.email'),
  message: t('addOpinion.message'),
  send: t('addOpinion.send'),
  sending: t('addOpinion.sending'),
  successTitle: t('addOpinion.success.title'),
  successText: t('addOpinion.success.text'),
  successConfirmButtonText: t('addOpinion.success.confirmButtonText'),
  errorTitle: t('addOpinion.error.title'),
  errorText: t('addOpinion.error.text'),
  errorConfirmButtonText: t('addOpinion.error.confirmButtonText'),
  validationRequired: t('addOpinion.validation.required'),
  validationEmail: t('addOpinion.validation.email')
 }


  const showSuccessAlert = () => {
    Swal.fire({
      title: translation.successTitle,
      text: translation.successText,
      icon: 'success',
      confirmButtonText: translation.successConfirmButtonText,
      confirmButtonColor: '#1a237e',
      timer: 3000,
      timerProgressBar: true,
      customClass: {
        popup: 'swal2-rtl'
      }
    });
  };

  const showErrorAlert = (message) => {
    Swal.fire({
      title: translation.errorTitle,
      text: message,
      icon: 'error',
      confirmButtonText: translation.errorConfirmButtonText,
      confirmButtonColor: '#1a237e',
      customClass: {
        popup: 'swal2-rtl'
      }
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess(false);
    setIsSubmitting(true);

    // Validate required fields
    if (!opinion.name || !opinion.position || !opinion.message) {
      setError(translation.validationRequired);
      setIsSubmitting(false);
      return;
    }

    // Validate email format if provided
    if (opinion.email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(opinion.email)) {
      setError(translation.validationEmail);
      setIsSubmitting(false);
      return;
    }

    try {
      await createOpinion(opinion);
      showSuccessAlert();
      setOpinion({
        name: '',
        position: '',
        message: '',
        email: ''
      });
    } catch (err) {
      showErrorAlert(translation.errorText);
      console.error(err);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        background: 'linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)',
        py: 8,
      }}
    >
      <Container maxWidth="md">
        <Paper
          elevation={3}
          sx={{
            p: { xs: 3, md: 6 },
            borderRadius: 2,
            background: 'rgba(255, 255, 255, 0.9)',
            backdropFilter: 'blur(10px)',
            boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)'
          }}
        >
          <Typography 
            variant="h2" 
            component="h1" 
            gutterBottom
            sx={{
              fontWeight: 700,
              mb: 4,
              textAlign: 'center',
              color: '#1a237e',
              fontSize: { xs: '2.5rem', md: '3.5rem' }
            }}
          >
            {translation.title}
          </Typography>

          {error && (
            <Alert severity="error" sx={{ mb: 2, borderRadius: 2 }}>
              {error}
            </Alert>
          )}

          {success && (
            <Alert severity="success" sx={{ mb: 2, borderRadius: 2 }}>
              {translation.successText}
            </Alert>
          )}

          <Box 
            component="form" 
            onSubmit={handleSubmit} 
            noValidate
            sx={{
              direction: isRTL ? 'rtl' : 'ltr',
              '& .MuiTextField-root': {
                '& .MuiOutlinedInput-root': {
                  '&:hover fieldset': {
                    borderColor: '#1a237e',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#1a237e',
                  },
                  '& textarea, & input': {
                    textAlign: isRTL ? 'right' : 'left',
                  },
                  '& fieldset': {
                    textAlign: isRTL ? 'right' : 'left', // Update fieldset alignment
                    '& legend': {
                      marginRight: isRTL ? '0px' : 'auto',
                      marginLeft: isRTL ? 'auto' : '0px',
                    }
                  }
                },
                '& .MuiInputLabel-root': {
                  left: isRTL ? 'auto' : '2px !important',
                  right: isRTL ? '29px' : 'auto !important',
                  transformOrigin: isRTL ? 'top right' : 'top left',
                  textAlign: isRTL ? 'right' : 'left'
                }
              }
            }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              label={translation.name}
              maxLength={30}
              value={opinion.name}
              onChange={(e) => setOpinion(prev => ({ ...prev, name: e.target.value }))}
              sx={{ mb: 3 }}
              inputProps={{
                dir: isRTL ? 'rtl' : 'ltr',
                maxLength: 32
              }}
            />

            <TextField
              margin="normal"
              required
              fullWidth
              label={translation.position}
              maxLength={100}
              value={opinion.position}
              onChange={(e) => setOpinion(prev => ({ ...prev, position: e.target.value }))}
              sx={{ mb: 3 }}
              inputProps={{
                dir: isRTL ? 'rtl' : 'ltr'
              }}
            />

            <TextField
              margin="normal"
              fullWidth
              label={translation.email}
              type="email"
              maxLength={100}
              value={opinion.email}
              onChange={(e) => setOpinion(prev => ({ ...prev, email: e.target.value }))}
              sx={{ mb: 3 }}
              inputProps={{
                dir: isRTL ? 'rtl' : 'ltr'
              }}
            />

            <TextField
              margin="normal"
              required
              fullWidth
              label={translation.message}
              multiline
              rows={4}
              maxLength={80}
              value={opinion.message}
              onChange={(e) => setOpinion(prev => ({ ...prev, message: e.target.value }))}
              sx={{ mb: 4 }}
              inputProps={{
                dir: isRTL ? 'rtl' : 'ltr'
              }}
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={isSubmitting}
              size="large"
              sx={{
                mb: 2,
                py: 1.5,
                backgroundColor: '#1a237e',
                fontSize: '1.1rem',
                fontWeight: 600,
                '&:hover': {
                  backgroundColor: '#000051',
                },
                '&.Mui-disabled': {
                  backgroundColor: '#9fa8da',
                },
                borderRadius: 2,
                transition: 'all 0.3s ease'
              }}
            >
              {isSubmitting ? translation.sending : translation.send}
            </Button>
          </Box>
        </Paper>
      </Container>
    </Box>
  );
}

export default AddOpinion; 