import React, { useRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Typography, Button, Box, Grid, Paper, TextField, IconButton, Stack, Avatar, Fab } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import SchoolIcon from '@mui/icons-material/School';
// import WorkIcon from '@mui/icons-material/Work';
// import BookIcon from '@mui/icons-material/Book';
import StarIcon from '@mui/icons-material/Star';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
// import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
// import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { useLocation } from 'react-router-dom';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ChatIcon from '@mui/icons-material/Chat';
import doctorRandomImage from '../../Images/doctorRandomImage.png';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import { subscribeToVisibleOpinions } from '../../services/opinionService';
import Swal from 'sweetalert2';
import { createContact } from '../../services/contactService';
import { useTranslation } from 'react-i18next';

// Define a modern, soothing color palette based on trending colors
const theme = {
  primary: '#2D3047',    // Deep blue-grey
  secondary: '#419D78',  // Soft green
  accent: '#E0A458',     // Warm orange
  background: '#FAF9F9', // Off-white
  footer: {
    background: 'linear-gradient(135deg, #2D3047 0%, #419D78 100%)',
    text: '#ffffff',
    linkHover: '#E0A458'
  },
  cards: {
    purple: {
      bg: '#E9ECEF',
      accent: '#7952B3',
      text: '#2D3047'
    },
    green: {
      bg: '#E3F2E9',
      accent: '#419D78',
      text: '#2D3047'
    },
    orange: {
      bg: '#FDF6EC',
      accent: '#E0A458',
      text: '#2D3047'
    },
    blue: {
      bg: '#E7EFF6',
      accent: '#457B9D',
      text: '#2D3047'
    }
  }
};

// Update this animation style
const styles = `
  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-12px);
    }
    60% {
      transform: translateY(-6px);
    }
  }

  .jump-button {
    animation: bounce 2s cubic-bezier(.84,-.54,.31,.19) infinite;
  }

  .jump-button:hover {
    animation-play-state: paused;
  }
`;

// Add the styles to the document
const styleSheet = document.createElement("style");
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

function Home() {


  const [opinions, setOpinions] = useState([]);

  useEffect(() => {
    // Set up the subscription
    const unsubscribe = subscribeToVisibleOpinions((updatedOpinions) => {
      setOpinions(updatedOpinions);
    });

    // Clean up subscription when component unmounts
    return () => unsubscribe();
  }, []);

  const contactRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();

  // Add state for tracking current section
  const [isContactSection, setIsContactSection] = useState(false);
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir() === 'rtl';

  // Add intersection observer to detect contact section
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsContactSection(entry.isIntersecting);
      },
      { threshold: 0.3 } // Trigger when 30% of the section is visible
    );

    const contactSection = document.getElementById('contact');
    if (contactSection) {
      observer.observe(contactSection);
    }

    return () => {
      if (contactSection) {
        observer.unobserve(contactSection);
      }
    };
  }, []);

  // const scrollToContact = () => {
  //   contactRef.current?.scrollIntoView({ behavior: 'smooth' });
  // };

  const tranlsation = {
    heroSection: {
      doctorName: 'home.heroSection.doctorName',
      speciality: 'home.heroSection.speciality', 
      description: 'home.heroSection.description',
      bookNow: 'home.heroSection.bookNow',
      avaiableConsultation: 'home.heroSection.avaiableConsultation'
    },
    servicesSection: {
      title: 'home.servicesSection.title',
      services: {
        service1: {
          title: 'home.servicesSection.services.service1.title',
          description: 'home.servicesSection.services.service1.description'
        },
        service2: {
          title: 'home.servicesSection.services.service2.title',
          description: 'home.servicesSection.services.service2.description'
        },
        service3: {
          title: 'home.servicesSection.services.service3.title',
          description: 'home.servicesSection.services.service3.description'
        },
        service4: {
          title: 'home.servicesSection.services.service4.title',
          description: 'home.servicesSection.services.service4.description'
        }
      }
    },
    aboutUsSection: {
      title: 'home.aboutUsSection.title',
      description: 'home.aboutUsSection.description',
      services: {
        service1: 'home.aboutUsSection.services.service1.title',
        service2: 'home.aboutUsSection.services.service2.title',
        service3: 'home.aboutUsSection.services.service3.title',
        service4: 'home.aboutUsSection.services.service4.title',
        service5: 'home.aboutUsSection.services.service5.title'
      }
    },
    reviewsSection: {
      title: 'home.reviewsSection.title'
    },
    contactSection: {
      title: 'home.contactSection.title',
      form: {
        name: 'home.contactSection.form.name',
        email: 'home.contactSection.form.email',
        phone: 'home.contactSection.form.phone',
        subject: 'home.contactSection.form.subject',
        message: 'home.contactSection.form.message',
        send: 'home.contactSection.form.send',
        sending: 'home.contactSection.form.sending'
      },
      address: 'home.contactSection.address',
      addressValue: 'home.contactSection.addressValue',
      phone: 'home.contactSection.phone',
      email: 'home.contactSection.email',
      success: {
        title: 'home.contactSection.success.title',
        text: 'home.contactSection.success.text',
        confirmButtonText: 'home.contactSection.success.confirmButtonText'
      },
      error: {
        title: 'home.contactSection.error.title',
        text: 'home.contactSection.error.text',
        confirmButtonText: 'home.contactSection.error.confirmButtonText'
      }

    },
    footer: {
      copyright: 'home.footer.copyright',
      quickLinks: {
        title: 'home.footer.quickLinks.title',
        home: 'home.footer.quickLinks.home',
        about: 'home.footer.quickLinks.about',
        services: 'home.footer.quickLinks.services',
        bookNow: 'home.footer.quickLinks.bookNow',
        contact: 'home.footer.quickLinks.contact'
      },
      contactInfo: {
        title: 'home.footer.contactInfo.title'
      },
      socialMedia: {
        title: 'home.footer.socialMedia.title'
      }
    }
  }
  

  const handleNavClick = (e, sectionId) => {
    e.preventDefault();
    if (location.pathname !== '/') {
      window.location.href = `/#${sectionId}`;
    } else {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  // Update this function to handle scrolling to top
  const handleQuickLinkClick = (path) => {
    const pathMap = {
      'home.footer.quickLinks.home': 'top',
      'home.footer.quickLinks.about': '/#about', 
      'home.footer.quickLinks.services': '/#services',
      'home.footer.quickLinks.bookNow': '/consultation',
      'home.footer.quickLinks.contact': '/#contact'
    };

    if (pathMap[path] === 'top') {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else if (pathMap[path].startsWith('/#')) {
      handleNavClick(new Event('click'), pathMap[path].substring(2));
    } else {
      navigate(pathMap[path]);
    }
  };

  // Update the About Us section image
  const doctorImage = "https://img.freepik.com/free-photo/doctor-with-his-arms-crossed-white-background_1368-5790.jpg";

  // Update the slider settings
  const sliderSettings = {
    dots: true,
    infinite: opinions.length > 1, // Only enable infinite if more than 1 opinion
    speed: 500,
    slidesToShow: Math.min(3, opinions.length), // Show max 3 or total opinions, whichever is smaller
    slidesToScroll: 1,
    autoplay: opinions.length > 1, // Only autoplay if more than 1 opinion
    autoplaySpeed: 3000,
    rtl: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: Math.min(2, opinions.length),
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  // Add these state variables after other useState declarations
  const [contactForm, setContactForm] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });
  const [submitStatus, setSubmitStatus] = useState({ loading: false, error: null });

  // Add this handler function
  const handleContactChange = (e) => {
    const { name, value } = e.target;
    setContactForm(prev => ({
      ...prev,
      [name]: value
    }));
  };

  // Add this submission handler
  const handleContactSubmit = async (e) => {
    e.preventDefault();
    setSubmitStatus({ loading: true, error: null });
    
    try {
      await createContact(contactForm);
      setContactForm({ name: '', email: '', subject: '', message: '' });
      // Show success message
      Swal.fire({
        icon: 'success',
        title: t(tranlsation.contactSection.success.title),
        text: t(tranlsation.contactSection.success.text),
        confirmButtonText: t(tranlsation.contactSection.success.confirmButtonText),
        customClass: {
          container: 'swal2-rtl'
        }
      });
    } catch (error) {
      setSubmitStatus({ loading: false, error: t(tranlsation.contactSection.error.text) });
      Swal.fire({
        icon: 'error',
        title: t(tranlsation.contactSection.error.title),
        text: t(tranlsation.contactSection.error.text),
        confirmButtonText: t(tranlsation.contactSection.error.confirmButtonText),
        customClass: {
          container: 'swal2-rtl'
        }
      });
    }
    setSubmitStatus({ loading: false, error: null });
  };

  return (
    <Box sx={{ 
      bgcolor: '#f8f9fa', 
      minHeight: '100vh',
      direction: isRTL ? 'rtl' : 'ltr' // Update root direction
    }}>
      {/* Hero Section */}
      <Box 
        sx={{
          minHeight: '100vh',
          background: `linear-gradient(135deg, ${theme.primary} 0%, ${theme.secondary} 100%)`,
          position: 'relative',
          overflow: 'hidden',
          display: 'flex',
          alignItems: 'center',
          pt: { xs: 8, md: 0 }, // Adjusted top padding
          px: { xs: 2, md: 4 }, // Added horizontal padding
        }}
      >
        {/* Add subtle pattern overlay */}
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            opacity: 0.05,
            backgroundImage: 'radial-gradient(#fff 1px, transparent 1px)',
            backgroundSize: '20px 20px',

          }}
        />

        <Container 
          maxWidth="xl" 
          sx={{ 
            px: { xs: 1, md: 3 }, // Reduced container padding
            width: '100%', 
          }}
        >
          <Grid 
            container 
            spacing={{ xs: 2, md: 4 }} 
            alignItems="center"
            sx={{ 
              minHeight: { xs: 'auto', md: '90vh' },
              flexDirection: 'row', // Use row-reverse for RTL, row for LTR
              mx: 0
            }}
          >
            {/* Left Image Section */}
            <Grid item xs={12} md={6} sx={{ px: { xs: 1, md: 2 } }}>
              <Box
                component="img"
                src={doctorRandomImage}
                alt={t(tranlsation.heroSection.doctorName)}
                sx={{
                  width: '100%',
                  maxWidth: '500px', // Reduced max width
                  height: 'auto',
                  display: 'block',
                  mx: 'auto',
                  borderRadius: '10px',
                  filter: 'brightness(1.1)',
                  transition: 'transform 0.3s ease',
                  '&:hover': {
                    transform: 'scale(1.02)'
                  }
                }}
              />
            </Grid>

            {/* Right Content Section */}
            <Grid item xs={12} md={6} sx={{ px: { xs: 1, md: 2 } }}>
              <Box sx={{ 
                color: 'white',
                textAlign: isRTL ? 'right' : 'left',
                maxWidth: '600px'
              }}>
                <Typography 
                  variant="h1"
                  sx={{
                    fontSize: { xs: '2rem', md: '2.5rem', lg: '3rem' },
                    fontWeight: 700,
                    mb: 1
                  }}
                >
                  {t(tranlsation.heroSection.doctorName)}
                </Typography>

                <Typography 
                  variant="h2"
                  sx={{
                    fontSize: { xs: '1.2rem', md: '1.4rem' },
                    fontWeight: 500,
                    mb: 3,
                    opacity: 0.9
                  }}
                >
                  {t(tranlsation.heroSection.speciality)}
                </Typography>

                <Typography 
                  sx={{
                    fontSize: { xs: '1rem', md: '1.1rem' },
                    mb: 4,
                    opacity: 0.8,
                    lineHeight: 1.8,
                    maxWidth: '600px',
                    textAlign: isRTL ? 'right' : 'justify'
                  }}
                >
                  {t(tranlsation.heroSection.description)}
                </Typography>

                <Button
                  variant="contained"
                  sx={{
                    bgcolor: '#2ECC71',
                    color: 'white',
                    fontSize: '1.1rem',
                    py: 1.5,
                    px: 4,
                    borderRadius: '12px',
                    '&:hover': {
                      bgcolor: '#27AE60',
                      transform: 'translateY(-3px)',
                      boxShadow: '0 6px 20px rgba(46, 204, 113, 0.3)'
                    },
                    transition: 'all 0.3s ease',
                    mb: 1
                  }}
                >
                  {t(tranlsation.heroSection.bookNow)}
                </Button>

                {/* Chat Icon - Fixed Position */}
                {!isContactSection && (
                  <Fab
                    aria-label="chat"
                    className="jump-button"
                    sx={{
                      position: 'fixed',
                      bottom: 20,
                      left: 20,
                      width: 45,
                      height: 45,
                      minHeight: 'unset',
                      bgcolor: 'transparent',
                      boxShadow: 'none',
                      '&:hover': {
                        bgcolor: 'transparent',
                      },
                      zIndex: 1000,
                    }}
                    onClick={() => handleNavClick(new Event('click'), 'contact')}
                  >
                    <ChatIcon sx={{ fontSize: '2rem', color: '#2ECC71' }} />
                  </Fab>
                )}
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Services Section */}
      <Container id="services" maxWidth="lg" sx={{ py: { xs: 6, md: 10 } }}>
        <Typography 
          variant="h2" 
          sx={{
            color: theme.primary,
            textAlign: 'center',
            mb: 8,
            fontSize: '40px',
            fontWeight: '600',
            position: 'relative',
            '&::after': {
              content: '""',
              position: 'absolute',
              bottom: -16,
              left: '50%',
              transform: 'translateX(-50%)',
              width: 80,
              height: 4,
              bgcolor: theme.secondary,
              borderRadius: 2
            }
          }}
        >
          {t(tranlsation.servicesSection.title)}
        </Typography>

        <Grid 
          container 
          spacing={4}
          sx={{ 
            px: { xs: 2, md: 0 },
            '& .MuiGrid-item': {
              mb: { xs: 3, md: 0 }
            }
          }}
        >
          {[
            {
              icon: <PersonIcon sx={{ fontSize: 40 }} />,
              title: t(tranlsation.servicesSection.services.service1.title),
              desc: t(tranlsation.servicesSection.services.service1.description),
              colors: theme.cards.purple
            },
            {
              icon: <FamilyRestroomIcon sx={{ fontSize: 40 }} />,
              title: t(tranlsation.servicesSection.services.service2.title),
              desc: t(tranlsation.servicesSection.services.service2.description),
              colors: theme.cards.green
            },
            {
              icon: <WorkspacePremiumIcon sx={{ fontSize: 40 }} />,
              title: t(tranlsation.servicesSection.services.service3.title),
              desc: t(tranlsation.servicesSection.services.service3.description),
              colors: theme.cards.orange
            },
            {
              icon: <SchoolIcon sx={{ fontSize: 40 }} />,
              title: t(tranlsation.servicesSection.services.service4.title),
              desc: t(tranlsation.servicesSection.services.service4.description),
              colors: theme.cards.blue
            }
          ].map((service, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Box
                sx={{
                  height: '100%',
                  borderRadius: '20px',
                  bgcolor: service.colors.bg,
                  p: { xs: 4, md: 4 },
                  transition: 'all 0.3s ease',
                  border: '1px solid',
                  borderColor: 'transparent',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  textAlign: 'center',
                  mb: { xs: 4, md: 4 }, // Added consistent margin bottom
                  '&:hover': {
                    transform: 'translateY(-8px)',
                    borderColor: `${service.colors.accent}22`,
                    boxShadow: `0 20px 40px ${service.colors.accent}15`,
                    '& .service-icon': {
                      bgcolor: service.colors.accent,
                      color: 'white',
                      transform: 'scale(1.1)'
                    }
                  }
                }}
              >
                <Box
                  className="service-icon"
                  sx={{
                    width: { xs: 70, md: 80 },
                    height: { xs: 70, md: 80 },
                    borderRadius: '16px',
                    bgcolor: 'white',
                    color: service.colors.accent,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    mb: { xs: 2, md: 3 },
                    transition: 'all 0.3s ease',
                    boxShadow: `0 8px 20px ${service.colors.accent}15`
                  }}
                >
                  {service.icon}
                </Box>

                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: 700,
                    color: service.colors.text,
                    mb: 2,
                    fontSize: { xs: '1.2rem', md: '1.4rem' }
                  }}
                >
                  {service.title}
                </Typography>

                <Typography
                  sx={{
                    lineHeight: 1.8,
                    fontSize: { xs: '0.9rem', md: '1.1rem' },
                    color: '#6b6b6b',
                    textAlign: isRTL ? 'center' : 'left',
                  }}
                >
                  {service.desc}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>

      {/* About Us Section - Updated */}
      <Box id="about" sx={{ bgcolor: '#fff', py: 8, mb: 8 }}>
        <Container maxWidth="lg">
          <Grid container spacing={6} alignItems="center">
            <Grid item xs={12} md={6}>
              <Box
                component="img"
                src={doctorImage}
                alt="صورة الدكتور"
                sx={{
                  width: '100%',
                  height: 'auto',
                  borderRadius: '20px',
                  boxShadow: '0 20px 40px rgba(0,0,0,0.1)',
                  transition: 'transform 0.3s ease',
                  '&:hover': {
                    transform: 'scale(1.02)'
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography 
                variant="h4" 
                component="h2" 
                sx={{ 
                  mb: 4, 
                  color: '#1a237e',
                  fontSize: '40px',
                  textAlign: 'center',
                  fontWeight: 'bold',
                  position: 'relative',
                  // '&::after': {
                  //   content: '""',
                  //   position: 'absolute',
                  //   bottom: -10,
                  //   right: 0,
                  //   width: 60,
                  //   height: 3,
                  //   bgcolor: '#4CAF50',
                  //   borderRadius: 1.5
                  // }
                }}
              >
              {t(tranlsation.aboutUsSection.title)}
              </Typography>
              <Typography variant="body1" sx={{ mb: 3, fontSize: '1.1rem', lineHeight: 1.8, textAlign: 'justify', }}>
                {t(tranlsation.aboutUsSection.description)}
              </Typography>
              <Box sx={{ mb: 4 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <StarIcon sx={{ color: '#4CAF50', mr: 2 }} />
                  <Typography variant="body1">
                    {t(tranlsation.aboutUsSection.services.service1)}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <StarIcon sx={{ color: '#4CAF50', mr: 2 }} />
                  <Typography variant="body1">
                    {t(tranlsation.aboutUsSection.services.service2)}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <StarIcon sx={{ color: '#4CAF50', mr: 2 }} />
                  <Typography variant="body1">
                    {t(tranlsation.aboutUsSection.services.service3)}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <StarIcon sx={{ color: '#4CAF50', mr: 2 }} />
                  <Typography variant="body1">
                    {t(tranlsation.aboutUsSection.services.service4)}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Testimonials Section */}
      <Container maxWidth="lg" sx={{ py: 10 }}>
        {/* Section Title */}
        <Typography 
          variant="h2" 
          sx={{
            color: theme.primary,
            textAlign: 'center',
            mb: 8,
            position: 'relative',
            fontWeight: 600,
            fontSize: '40px',
            '&::after': {
              content: '""',
              position: 'absolute',
              bottom: -16,
              left: '50%',
              transform: 'translateX(-50%)',
              width: 80,
              height: 4,
              bgcolor: theme.secondary,
              borderRadius: 2
            }
          }}
        >
          {t(tranlsation.reviewsSection.title)}
        </Typography>

        {/* Testimonials Slider */}
        {opinions.length > 0 && (
          <Slider {...sliderSettings}>
            {opinions.map((opinion, index) => (
              <Box key={opinion.id || index} sx={{ px: 2, py: 4, height: '100%' }}>
                <Paper
                  elevation={0}
                  sx={{
                    p: 4,
                    m: 1,
                    borderRadius: '24px',
                    height: '250px', // Fixed height for all boxes
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'relative',
                    background: 'linear-gradient(145deg, #ffffff 0%, #f8f9fa 100%)',
                    boxShadow: '0 10px 40px rgba(0,0,0,0.05)',
                    transition: 'all 0.3s ease',
                    overflow: 'visible',
                    '&:hover': {
                      transform: 'translateY(-10px)',
                      boxShadow: '0 20px 40px rgba(0,0,0,0.1)',
                      '& .quote-icon': {
                        transform: 'rotate(-10deg) scale(1.1)',
                        bgcolor: theme.cards[Object.keys(theme.cards)[index % 4]].accent,
                        color: 'white'
                      }
                    }
                  }}
                >
                  {/* Quote Icon */}
                  <Box
                    className="quote-icon"
                    sx={{
                      position: 'absolute',
                      top: -20,
                      right: 30,
                      width: 50,
                      height: 50,
                      borderRadius: '15px',
                      bgcolor: 'white',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      boxShadow: '0 8px 20px rgba(0,0,0,0.1)',
                      transition: 'all 0.3s ease',
                      color: theme.cards[Object.keys(theme.cards)[index % 4]].accent
                    }}
                  >
                    <FormatQuoteIcon />
                  </Box>

                  {/* Testimonial Content */}
                  <Box sx={{ 
                    position: 'relative', 
                    zIndex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    height: '100%'
                  }}>
                    <Typography
                      sx={{
                        fontSize: '1.1rem',
                        lineHeight: 1.8,
                        color: '#555',
                        fontStyle: 'italic',
                        overflow: 'hidden',
                        display: '-webkit-box',
                        WebkitLineClamp: 4,
                        WebkitBoxOrient: 'vertical',
                        textOverflow: 'ellipsis'
                      }}
                    >
                      "{opinion.message}"
                    </Typography>

                    {/* Author Info */}
                    <Box sx={{ 
                      display: 'flex', 
                      alignItems: 'center', 
                      gap: 2,
                      mt: 'auto', // Push to bottom
                      pt: 3 // Add some padding from the message
                    }}>
                      <Avatar
                        sx={{
                          bgcolor: `${theme.cards[Object.keys(theme.cards)[index % 4]].accent}15`,
                          color: theme.cards[Object.keys(theme.cards)[index % 4]].accent,
                          width: 56,
                          height: 56,
                          fontSize: '1.5rem'
                        }}
                      >
                        {opinion.name[0]}
                      </Avatar>
                      <Box>
                        <Typography
                          variant="h6"
                          sx={{
                            fontWeight: 700,
                            color: theme.primary,
                            fontSize: '1.1rem'
                          }}
                        >
                          {opinion.name}
                        </Typography>
                        <Typography
                          sx={{
                            color: theme.cards[Object.keys(theme.cards)[index % 4]].accent,
                            fontSize: '0.9rem'
                          }}
                        >
                          {opinion.position}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Paper>
              </Box>
            ))}
          </Slider>
        )}
      </Container>

      {/* Contact Us Section - Modernized */}
      <Box id="contact" ref={contactRef} sx={{ bgcolor: '#f8f9fa', py: 12 }}>
        <Container maxWidth="lg">
          <Typography 
            variant="h3" 
            component="h2" 
            textAlign="center" 
            sx={{ 
              mb: 8,
              color: '#1a237e',
              fontWeight: 600,
              fontSize: '40px',
              position: 'relative',
              '&::after': {
                content: '""',
                position: 'absolute',
                bottom: -16,
                left: '50%',
                transform: 'translateX(-50%)',
                width: 60,
                height: 4,
                background: 'linear-gradient(90deg, #4CAF50 0%, #45a049 100%)',
                borderRadius: 2
              }
            }}
          >
           {t(tranlsation.contactSection.title)}
          </Typography>
          <Grid container spacing={6} alignItems="flex-start"> 
            <Grid item xs={12} md={5}>
              <Box sx={{ 
                p: 4, 
                bgcolor: '#fff',
                borderRadius: '20px',
                boxShadow: '0 10px 30px rgba(0,0,0,0.05)',
                mb: { xs: 4, md: 0 }
              }}>
                <Typography variant="h5" sx={{ mb: 4, color: '#1a237e', fontWeight: 600 }}>
                  {t(tranlsation.footer.contactInfo.title)}
                </Typography>
                
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                  <Box sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    gap: 2,
                    p: 2,
                    borderRadius: '10px',
                    bgcolor: '#f8f9fa',
                    transition: 'all 0.3s ease',
                    '&:hover': {
                      transform: 'translateX(-5px)',
                      bgcolor: '#f0f7f0'
                    }
                  }}>
                    <Box sx={{ 
                      bgcolor: '#4CAF50',
                      p: 1,
                      borderRadius: '50%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}>
                      <PhoneIcon sx={{ color: '#fff', fontSize: 20 }} />
                    </Box>
                    <Box>
                      <Typography variant="body2" color="text.secondary" sx={{ mb: 0.5 }}>
                        {t(tranlsation.contactSection.phone)}
                      </Typography>
                      <Typography variant="body1" sx={{ fontWeight: 500, direction: 'ltr' }}>
                        +966 50 123 4567
                      </Typography>
                    </Box>
                  </Box>

                  <Box sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    gap: 2,
                    p: 2,
                    borderRadius: '10px',
                    bgcolor: '#f8f9fa',
                    transition: 'all 0.3s ease',
                    '&:hover': {
                      transform: 'translateX(-5px)',
                      bgcolor: '#f0f7f0'
                    }
                  }}>
                    <Box sx={{ 
                      bgcolor: '#4CAF50',
                      p: 1,
                      borderRadius: '50%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}>
                      <EmailIcon sx={{ color: '#fff', fontSize: 20 }} />
                    </Box>
                    <Box>
                      <Typography variant="body2" color="text.secondary" sx={{ mb: 0.5 }}>
                        {t(tranlsation.contactSection.email)}
                      </Typography>
                      <Typography variant="body1" sx={{ fontWeight: 500 }}>
                        info@example.com
                      </Typography>
                    </Box>
                  </Box>

                  <Box sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    gap: 2,
                    p: 2,
                    borderRadius: '10px',
                    bgcolor: '#f8f9fa',
                    transition: 'all 0.3s ease',
                    '&:hover': {
                      transform: 'translateX(-5px)',
                      bgcolor: '#f0f7f0'
                    }
                  }}>
                    <Box sx={{ 
                      bgcolor: '#4CAF50',
                      p: 1,
                      borderRadius: '50%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}>
                      <LocationOnIcon sx={{ color: '#fff', fontSize: 20 }} />
                    </Box>
                    <Box>
                      <Typography variant="body2" color="text.secondary" sx={{ mb: 0.5 }}>
                        {t(tranlsation.contactSection.address)}
                      </Typography>
                      <Typography variant="body1" sx={{ fontWeight: 500 }}>
                        {t(tranlsation.contactSection.addressValue)}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} md={7}>
              <Box 
                component="form" 
                Validate 
                onSubmit={handleContactSubmit}
                sx={{ 
                  bgcolor: '#fff',
                  p: 4,
                  borderRadius: '20px',
                  boxShadow: '0 10px 30px rgba(0,0,0,0.05)',
                  direction: isRTL ? 'rtl' : 'ltr', // Update form direction
                  '& .MuiTextField-root': {
                    '& .MuiOutlinedInput-root': {
                      '&:hover fieldset': {
                        borderColor: '#4CAF50',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#4CAF50',
                      },
                      '& input': {
                        textAlign: isRTL ? 'right' : 'left', // Update input text alignment
                      },
                      '& fieldset': {
                        textAlign: isRTL ? 'right' : 'left', // Update fieldset alignment
                        '& legend': {
                          marginRight: isRTL ? '8px' : 'auto',
                          marginLeft: isRTL ? 'auto' : '8px',
                        }
                      }
                    },
                    '& .MuiFormLabel-root': {
                      right: isRTL ? '36px !important' : 'auto !important',
                      left: isRTL ? 'auto !important' : '8px !important',
                      transformOrigin: isRTL ? 'top right !important' : 'top left !important',
                    },
                    '& .MuiFormLabel-asterisk': {
                      marginLeft: isRTL ? '0px !important' : '4px !important',
                      marginRight: isRTL ? '4px !important' : '0px !important'
                    }
                  }
                }}
              >
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      type="text"
                      fullWidth
                      label={t(tranlsation.contactSection.form.name)}
                      variant="outlined"
                      name="name"
                      value={contactForm.name}
                      onChange={handleContactChange}
                      inputProps={{
                        maxLength: 32
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      fullWidth
                      type="email"
                      label={t(tranlsation.contactSection.form.email)}
                      variant="outlined"
                      name="email"
                      value={contactForm.email}
                      onChange={handleContactChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      label={t(tranlsation.contactSection.form.subject)}
                      variant="outlined"
                      name="subject" 
                      value={contactForm.subject}
                      onChange={handleContactChange}
                      inputProps={{
                        maxLength: 30
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      multiline
                      rows={4}
                      label={t(tranlsation.contactSection.form.message)}
                      variant="outlined"
                      name="message"
                      value={contactForm.message}
                      onChange={handleContactChange}
                      inputProps={{
                        maxLength: 100
                      }}
                    />
                  </Grid>
                </Grid>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  disabled={submitStatus.loading}
                  sx={{
                    mt: 4,
                    bgcolor: '#4CAF50',
                    color: '#fff',
                    borderRadius: '10px',
                    py: 1.5,
                    fontSize: '1.1rem',
                    transition: 'all 0.3s ease',
                    '&:hover': {
                      bgcolor: '#45a049',
                      transform: 'translateY(-2px)',
                      boxShadow: '0 10px 20px rgba(76, 175, 80, 0.2)',
                    }
                  }}
                >
                  {submitStatus.loading ? t(tranlsation.contactSection.form.sending) : t(tranlsation.contactSection.form.send)}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Modern Footer with Gradient Background */}
      <Box 
        component="footer" 
        sx={{
          background: theme.footer.background,
          color: theme.footer.text,
          pt: 8,
          pb: 4,
          position: 'relative',
          overflow: 'hidden'
        }}
      >
        {/* Add subtle pattern overlay - same as hero */}
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            opacity: 0.05,
            backgroundImage: 'radial-gradient(#fff 1px, transparent 1px)',
            backgroundSize: '20px 20px'
          }}
        />

        <Container maxWidth="lg" sx={{ position: 'relative', zIndex: 1 }}>
          <Grid container spacing={4}>
            {/* Quick Links */}
            <Grid item xs={12} md={4}>
              <Typography variant="h6" sx={{ mb: 3, color: 'white', fontWeight: 600 }}>
                {t(tranlsation.footer.quickLinks.title)}
              </Typography>
              <Stack spacing={1} sx={{ 
                alignItems: 'flex-start'
              }}>
                {['home.footer.quickLinks.home', 'home.footer.quickLinks.about', 'home.footer.quickLinks.services', 'home.footer.quickLinks.bookNow', 'home.footer.quickLinks.contact'].map((link) => (
                  <Button
                    key={link}
                    onClick={() => handleQuickLinkClick(link)}
                    sx={{
                      color: 'white',
                      justifyContent: 'flex-start',
                      '&:hover': {
                        color: theme.footer.linkHover,
                        bgcolor: 'transparent'
                      }
                    }}
                  >
                    {t(link)}
                  </Button>
                ))}
              </Stack>
            </Grid>

            {/* Contact Info */}
            <Grid item xs={12} md={4}>
              <Typography variant="h6" sx={{ mb: 3, color: 'white', fontWeight: 600 }}>
              {t(tranlsation.footer.contactInfo.title)}
              </Typography>
              <Stack spacing={2}>
                {[
                  { icon: <EmailIcon />, text: 'info@example.com' },
                  { icon: <PhoneIcon />, text: '+966 789 456 123', isPhone: true },
                  { icon: <LocationOnIcon />, text: t(tranlsation.contactSection.addressValue) }
                ].map((item, index) => (
                  <Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: 1, color: 'white' }}>
                    {React.cloneElement(item.icon, { sx: { color: 'white' } })}
                    <Typography sx={{ 
                      color: 'white', 
                      direction: item.isPhone ? 'ltr' : 'rtl',
                      textAlign: item.isPhone ? 'left' : 'right'
                    }}>
                      {item.text}
                    </Typography>
                  </Box>
                ))}
              </Stack>
            </Grid>

            {/* Social Media */}
            <Grid item xs={12} md={4}>
              <Typography variant="h6" sx={{ mb: 3, color: 'white', fontWeight: 600 }}>
                {t(tranlsation.footer.socialMedia.title)}
              </Typography>
              <Stack direction="row" spacing={2} sx={{ ml: 0 }}>
                {[
                  { icon: <LinkedInIcon />, color: '#0A66C2' },
                  { icon: <TwitterIcon />, color: '#1DA1F2' },
                  { icon: <WhatsAppIcon />, color: '#25D366' }
                ].map((social, index) => (
                  <IconButton
                    key={index}
                    sx={{
                      color: 'white',
                      ml: '0 !important', // Force remove margin-left
                      '&:hover': {
                        color: social.color,
                        transform: 'translateY(-3px)',
                        bgcolor: 'rgba(255,255,255,0.1)'
                      },
                      transition: 'all 0.3s ease'
                    }}
                  >
                    {social.icon}
                  </IconButton>
                ))}
              </Stack>
            </Grid>
          </Grid>

          {/* Copyright */}
          <Typography 
            sx={{ 
              textAlign: 'center', 
              mt: 8, 
              pt: 3, 
              borderTop: '1px solid rgba(255,255,255,0.1)',
              color: 'rgba(255,255,255,0.7)'
            }}
          >
            © {new Date().getFullYear()} {t(tranlsation.footer.copyright)}
          </Typography>
        </Container>
      </Box>
    </Box>
  );
}

export default Home; 