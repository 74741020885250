import { db } from '../config/firebase';
import { 
  collection, 
  addDoc, 
  updateDoc, 
  deleteDoc, 
  doc, 
  query, 
  orderBy, 
  onSnapshot 
} from 'firebase/firestore';

const COLLECTION_NAME = 'contacts';

// Create new contact
export const createContact = async (contactData) => {
  try {
    const docRef = await addDoc(collection(db, COLLECTION_NAME), {
      ...contactData,
      createdAt: new Date().toISOString(),
      isRead: false
    });
    return docRef.id;
  } catch (error) {
    throw new Error(`Failed to create contact: ${error.message}`);
  }
};

// Subscribe to contacts (real-time)
export const subscribeToContacts = (callback) => {
  try {
    const q = query(
      collection(db, COLLECTION_NAME),
      orderBy('createdAt', 'desc')
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const contacts = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      callback(contacts);
    }, (error) => {
      console.error("Error fetching contacts:", error);
      callback([], error);
    });

    return unsubscribe;
  } catch (error) {
    console.error("Error setting up contact subscription:", error);
    callback([], error);
    return () => {};
  }
};

// Update contact (mark as read/unread)
export const updateContact = async (id, updateData) => {
  try {
    const contactRef = doc(db, COLLECTION_NAME, id);
    await updateDoc(contactRef, {
      ...updateData,
      updatedAt: new Date().toISOString()
    });
    return true;
  } catch (error) {
    throw new Error(`Failed to update contact: ${error.message}`);
  }
};

// Delete contact
export const deleteContact = async (id) => {
  try {
    const contactRef = doc(db, COLLECTION_NAME, id);
    await deleteDoc(contactRef);
    return true;
  } catch (error) {
    throw new Error(`Failed to delete contact: ${error.message}`);
  }
};

// Mark contact as read
export const markAsRead = async (id, isRead) => {
  try {
    const contactRef = doc(db, COLLECTION_NAME, id);
    await updateDoc(contactRef, {
      isRead: isRead,
      updatedAt: new Date().toISOString()
    });
    return true;
  } catch (error) {
    throw new Error(`Failed to update contact status: ${error.message}`);
  }
};
