import React, { useState, useEffect } from 'react';
import { 
  Container, 
  Typography, 
  Paper, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow,
  Switch,
  IconButton,
  Box,
  InputBase,
  CircularProgress
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { subscribeToAllOpinions, removeOpinion } from '../../services/opinionService';
import Swal from 'sweetalert2';
import SearchIcon from '@mui/icons-material/Search';
import TableSortLabel from '@mui/material/TableSortLabel';
import { useTranslation } from 'react-i18next';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';

function AdminOpinions() {
  const [opinions, setOpinions] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortField, setSortField] = useState('createdAt');
  const [sortDirection, setSortDirection] = useState('desc');
  const [loading, setLoading] = useState(true);
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir() === 'rtl';

  const translation = {
    title: t('adminOpenion.title'),
    name: t('adminOpenion.name'),
    position: t('adminOpenion.position'),
    message: t('adminOpenion.message'),
    email: t('adminOpenion.email'),
    date: t('adminOpenion.date'),
    status: t('adminOpenion.status'),
    delete: t('adminOpenion.delete'),
    noOpinions: t('adminOpenion.noOpinions'),
    search: t('adminOpenion.search'),
    confirm: {
      title: t('adminOpenion.confirm.title'),
      text: t('adminOpenion.confirm.text'),
      confirmButtonText: t('adminOpenion.confirm.confirmButtonText'),
      cancelButtonText: t('adminOpenion.confirm.cancelButtonText')
    },
    success: {
      title: t('adminOpenion.success.title'),
      text: t('adminOpenion.success.text'),
      confirmButtonText: t('adminOpenion.success.confirmButtonText')
    },
    error: {
      title: t('adminOpenion.error.title'),
      text: t('adminOpenion.error.text'),
      confirmButtonText: t('adminOpenion.error.confirmButtonText')
    }
  }


  useEffect(() => {
    // Set up the subscription
    const unsubscribe = subscribeToAllOpinions((updatedOpinions) => {
      // Sort opinions by date in descending order
      const sortedOpinions = [...updatedOpinions].sort((a, b) => 
        new Date(b.createdAt) - new Date(a.createdAt)
      );
      setOpinions(sortedOpinions);
      setLoading(false);
    });

    // Clean up subscription when component unmounts
    return () => unsubscribe();
  }, []);

  const handleVisibilityToggle = async (id, currentVisibility) => {
    try {
      const opinionRef = doc(db, 'opinions', id);
      await updateDoc(opinionRef, {
        isVisible: !currentVisibility
      });
      // No need to update state manually as the subscription will handle it
    } catch (error) {
      console.error('Error updating opinion visibility:', error);
    }
  };

  const handleDelete = async (id) => {
    const result = await Swal.fire({
      title: translation.confirm.title,
      text: translation.confirm.text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: translation.confirm.confirmButtonText,
      cancelButtonText: translation.confirm.cancelButtonText,
      customClass: {
        popup: 'swal2-rtl'
      }
    });

    if (result.isConfirmed) {
      try {
        await removeOpinion(id);
        Swal.fire({
          title: translation.success.title,
          text: translation.success.text,
          icon: 'success',
          confirmButtonText: translation.success.confirmButtonText,
          customClass: {
            popup: 'swal2-rtl'
          }
        });
      } catch (error) {
        console.error('Error deleting opinion:', error);
        Swal.fire({
          title: translation.error.title,
          text: translation.error.text,
          icon: 'error',
          confirmButtonText: translation.error.confirmButtonText,
          customClass: {
            popup: 'swal2-rtl'
          }
        });
      }
    }
  };

  const handleSortClick = (field) => {
    if (field === sortField) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
  };

  const filteredAndSortedOpinions = opinions
    .filter(opinion => 
      opinion.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      opinion.position.toLowerCase().includes(searchTerm.toLowerCase()) ||
      opinion.message.toLowerCase().includes(searchTerm.toLowerCase()) ||
      opinion.email.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .sort((a, b) => {
      if (sortField === 'createdAt') {
        return sortDirection === 'asc' 
          ? new Date(a.createdAt) - new Date(b.createdAt)
          : new Date(b.createdAt) - new Date(a.createdAt);
      }
      return sortDirection === 'asc'
        ? a[sortField]?.localeCompare(b[sortField])
        : b[sortField]?.localeCompare(a[sortField]);
    });

  return (
    <Container maxWidth="xl" sx={{ mt: 4, direction: isRTL ? 'rtl' : 'ltr' }}>
      <Typography 
        variant="h4" 
        component="h1" 
        sx={{ 
          fontWeight: 600,
          color: '#2c3e50',
          fontSize: { xs: '1.5rem', sm: '1.8rem', md: '2rem' },
          textAlign: 'center',
          my: 3
        }}
      >
        {translation.title}
      </Typography>

      <Box sx={{ 
        width: '100%', 
        maxWidth: '1600px',
        display: 'flex', 
        alignItems: 'center',
        gap: 2,
        px: { xs: 2, sm: 3, md: 0 },
        mb: 3
      }}>
        <Box sx={{ 
          display: 'flex',
          flex: 1,
          position: 'relative',
          bgcolor: '#f8f9fa',
          borderRadius: '12px',
          border: '1px solid #e0e0e0',
          height: '50px'
        }}>
          <SearchIcon sx={{ 
            position: 'absolute',
            [isRTL ? 'right' : 'left']: 16,
            top: '50%',
            transform: 'translateY(-50%)',
            color: '#94a3b8',
            fontSize: '1.5rem'
          }} />
          <InputBase
            placeholder={translation.search + "..."}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{
              flex: 1,
              pl: isRTL ? 3 : 6,
              pr: isRTL ? 6 : 3,
              fontSize: '1.1rem',
              height: '100%',
              direction: isRTL ? 'rtl' : 'ltr'
            }}
          />
        </Box>
      </Box>

      {filteredAndSortedOpinions.length > 0 ? (
        <TableContainer 
          component={Paper} 
          sx={{ 
            boxShadow: '0 2px 12px rgba(0,0,0,0.08)',
            borderRadius: '12px',
            overflow: 'auto',
            width: '100%',
            maxWidth: '1600px',
            margin: '0 auto',
            '& .MuiTableCell-root': {
              py: 2.5,
              px: 3,
              fontSize: '1rem',
              whiteSpace: 'nowrap',
              minWidth: '150px',
            },
            position: 'relative',
            minHeight: '200px'
          }}
        >
          <Table dir={isRTL ? 'rtl' : 'ltr'}>
            <TableHead>
              <TableRow sx={{ bgcolor: '#f8f9fa' }}>
                <TableCell 
                  align={isRTL ? 'right' : 'left'} 
                  sx={{ 
                    fontWeight: 600,
                    color: '#2c3e50',
                    fontSize: '0.95rem',
                    borderBottom: '2px solid #e9ecef',
                  }}
                >
                  <TableSortLabel
                    active={sortField === 'name'}
                    direction={sortField === 'name' ? sortDirection : 'asc'}
                    onClick={() => handleSortClick('name')}
                  >
                    {translation.name}
                  </TableSortLabel>
                </TableCell>
                <TableCell 
                  align={isRTL ? 'right' : 'left'}
                  sx={{ 
                    fontWeight: 600,
                    color: '#2c3e50',
                    fontSize: '0.95rem',
                    borderBottom: '2px solid #e9ecef',
                  }}
                >
                  <TableSortLabel
                    active={sortField === 'position'}
                    direction={sortField === 'position' ? sortDirection : 'asc'}
                    onClick={() => handleSortClick('position')}
                  >
                    {translation.position}
                  </TableSortLabel>
                </TableCell>
                <TableCell 
                  align={isRTL ? 'right' : 'left'}
                  sx={{ 
                    fontWeight: 600,
                    color: '#2c3e50',
                    fontSize: '0.95rem',
                    borderBottom: '2px solid #e9ecef',
                  }}
                  className="message-cell"
                >
                  <TableSortLabel
                    active={sortField === 'message'}
                    direction={sortField === 'message' ? sortDirection : 'asc'}
                    onClick={() => handleSortClick('message')}
                  >
                    {translation.message}
                  </TableSortLabel>
                </TableCell>
                <TableCell 
                  align={isRTL ? 'right' : 'left'}
                  sx={{ 
                    fontWeight: 600,
                    color: '#2c3e50',
                    fontSize: '0.95rem',
                    borderBottom: '2px solid #e9ecef',
                  }}
                >
                  <TableSortLabel
                    active={sortField === 'email'}
                    direction={sortField === 'email' ? sortDirection : 'asc'}
                    onClick={() => handleSortClick('email')}
                  >
                    {translation.email}
                  </TableSortLabel>
                </TableCell>
                <TableCell 
                  align={isRTL ? 'right' : 'left'}
                  sx={{ 
                    fontWeight: 600,
                    color: '#2c3e50',
                    fontSize: '0.95rem',
                    borderBottom: '2px solid #e9ecef',
                  }}
                >
                  <TableSortLabel
                    active={sortField === 'createdAt'}
                    direction={sortField === 'createdAt' ? sortDirection : 'asc'}
                    onClick={() => handleSortClick('createdAt')}
                  >
                    {translation.date}
                  </TableSortLabel>
                </TableCell>
                <TableCell 
                  align="center"
                  sx={{ 
                    fontWeight: 600,
                    color: '#2c3e50',
                    fontSize: '0.95rem',
                    borderBottom: '2px solid #e9ecef',
                  }}
                >
                  {translation.status}
                </TableCell>
                <TableCell 
                  align="center"
                  sx={{ 
                    fontWeight: 600,
                    color: '#2c3e50',
                    fontSize: '0.95rem',
                    borderBottom: '2px solid #e9ecef',
                  }}
                >
                  {translation.delete}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredAndSortedOpinions.map((opinion) => (
                <TableRow key={opinion.id}>
                  <TableCell 
                    align={isRTL ? 'right' : 'left'} 
                    sx={{ 
                      color: '#34495e',
                      fontSize: '1rem',
                      fontWeight: 600
                    }}
                  >
                    {opinion.name}
                  </TableCell>
                  <TableCell 
                    align={isRTL ? 'right' : 'left'} 
                    sx={{ 
                      color: '#34495e',
                      fontSize: '1rem',
                      fontWeight: 600
                    }}
                  >
                    {opinion.position}
                  </TableCell>
                  <TableCell 
                    align={isRTL ? 'right' : 'left'} 
                    sx={{ 
                      color: '#34495e',
                      fontSize: '1.1rem',
                      maxWidth: '300px',
                      '& .content': {
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        transition: 'all 0.3s ease',
                        fontSize: '1rem',
                        fontWeight: 600
                      },
                      '&:hover .content': {
                        whiteSpace: 'wrap',
                        maxWidth: '500px'
                      }
                    }}
                  >
                    <div className="content">{opinion.message}</div>
                  </TableCell>
                  <TableCell 
                    align={isRTL ? 'right' : 'left'} 
                    sx={{ 
                      color: '#34495e',
                      fontSize: '1.1rem'
                    }}
                  >
                    {opinion.email}
                  </TableCell>
                  <TableCell 
                    align={isRTL ? 'right' : 'left'} 
                    sx={{ 
                      color: '#34495e',
                      fontSize: '1.1rem'
                    }}
                  >
                    {new Date(opinion.createdAt).toLocaleDateString('en-US', {
                      year: 'numeric',
                      month: 'short',
                      day: 'numeric'
                    })}
                  </TableCell>
                  <TableCell align="center">
                    <Switch
                      checked={opinion.isVisible}
                      onChange={() => handleVisibilityToggle(opinion.id, opinion.isVisible)}
                      color="primary"
                    />
                  </TableCell>
                  <TableCell align="center">
                    <IconButton 
                      onClick={() => handleDelete(opinion.id)}
                      sx={{ 
                        color: '#e74c3c',
                        '&:hover': { 
                          color: '#c0392b',
                          bgcolor: 'rgba(231, 76, 60, 0.1)'
                        }
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Box 
          sx={{ 
            textAlign: 'center', 
            py: 8,
            px: 2,
            bgcolor: '#f8f9fa',
            borderRadius: '12px',
            mt: 4,
            boxShadow: '0 2px 12px rgba(0,0,0,0.08)',
          }}
        >
          <Box 
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 2
            }}
          >
            <Box 
              sx={{
                width: 80,
                height: 80,
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                mb: 2
              }}
            >
              <SentimentDissatisfiedIcon 
                sx={{ 
                  fontSize: 40,
                  color: '#4CAF50'
                }} 
              />
            </Box>

            <Typography 
              variant="h6" 
              sx={{ 
                fontWeight: 600,
                color: '#2c3e50',
                mb: 1,
                direction: isRTL ? 'rtl' : 'ltr'
              }}
            >
              {translation.noOpinions}
            </Typography>
            
          </Box>
        </Box>
      )}

      {loading && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            bgcolor: 'rgba(255, 255, 255, 0.8)',
            zIndex: 1,
          }}
        >
          <CircularProgress 
            size={60}
            thickness={4}
            sx={{
              color: '#4CAF50',
            }}
          />
        </Box>
      )}
    </Container>
  );
}

export default AdminOpinions;