import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';
import PublicNavbar from './components/PublicNavbar';
import AdminNavbar from './components/AdminNavbar';
import Home from './pages/public/Home';
import BlogList from './pages/public/BlogList';
import BlogPost from './pages/public/BlogPost';
import Login from './pages/auth/Login';
import AdminBlogs from './pages/admin/AdminBlogs';
import BlogEditor from './pages/admin/BlogEditor';
import { useAuth } from './contexts/AuthContext';
import { Box } from '@mui/material';
import AddOpinion from './pages/public/AddOpinion';
import AdminOpinions from './pages/admin/AdminOpinions';
import AdminResearch from './pages/admin/AdminResearch';
import Research from './pages/public/Research';
import AdminContacts from './pages/admin/AdminContacts';
import './i18n/i18n';

function AppContent() {
  const { user } = useAuth();
  const location = useLocation();
  const isAdminPath = location.pathname.startsWith('/admin');
  const showAdminNavbar = user && isAdminPath;

  return (
    <Box 
      sx={{ 
        width: '100%',
        maxWidth: '100vw',
        overflowX: 'hidden',
        margin: 0,
        padding: 0
      }}
    >
      {showAdminNavbar ? <AdminNavbar /> : <PublicNavbar />}
      <Routes>
        {/* Public Routes */}
        <Route path="/" element={<Home />} />
        <Route path="/blogs" element={<BlogList />} />
        <Route path="/blog/:id" element={<BlogPost />} />
        <Route path="/add-opinion" element={<AddOpinion />} />
        <Route path="/login" element={<Login />} />
        <Route path="/research" element={<Research />} />

        {/* Protected Admin Routes */}
        <Route 
          path="/admin/*" 
          element={
            <PrivateRoute>
              <Routes>
                <Route path="blogs" element={<AdminBlogs />} />
                <Route path="opinions" element={<AdminOpinions />} />
                <Route path="research" element={<AdminResearch />} />
                <Route path="create" element={<BlogEditor />} />
                <Route path="edit/:id" element={<BlogEditor />} />
                <Route path="contacts" element={<AdminContacts />} />
              </Routes>
            </PrivateRoute>
          }
        />

        {/* Catch-all route - Redirect to home page */}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Box>
  );
}

// Wrap the AppContent in Router
function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;