import { db } from '../config/firebase';
import { collection, addDoc, updateDoc, doc, getDocs, query, orderBy, where, onSnapshot, deleteDoc } from 'firebase/firestore';

const COLLECTION_NAME = 'opinions';

export const createOpinion = async (opinionData) => {
  try {
    const docRef = await addDoc(collection(db, COLLECTION_NAME), {
      ...opinionData,
      isVisible: false, // Default to false for new opinions
      createdAt: new Date().toISOString()
    });
    return docRef.id;
  } catch (error) {
    throw new Error(`Failed to create opinion: ${error.message}`);
  }
};

export const getAllOpinions = async () => {
  try {
    const q = query(
      collection(db, COLLECTION_NAME),
      orderBy('createdAt', 'desc')
    );
    const querySnapshot = await getDocs(q);
    const opinions = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    return opinions;
  } catch (error) {
    throw new Error(`Failed to fetch opinions: ${error.message}`);
  }
};

export const getVisibleOpinions = async () => {
  try {
    const q = query(
      collection(db, COLLECTION_NAME),
      where('isVisible', '==', true),
      orderBy('createdAt', 'desc')
    );
    const querySnapshot = await getDocs(q);
    const opinions = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    return opinions;
  } catch (error) {
    throw new Error(`Failed to fetch visible opinions: ${error.message}`);
  }
};

export const toggleOpinionVisibility = async (id, isVisible) => {
  try {
    const opinionRef = doc(db, COLLECTION_NAME, id);
    await updateDoc(opinionRef, {
      isVisible: isVisible
    });
  } catch (error) {
    throw new Error(`Failed to update opinion visibility: ${error.message}`);
  }
};

export const removeOpinion = async (id) => {
  try {
    const opinionRef = doc(db, COLLECTION_NAME, id);
    await deleteDoc(opinionRef);
  } catch (error) {
    throw new Error(`Failed to remove opinion: ${error.message}`);
  }
};

export const subscribeToAllOpinions = (callback) => {
  const q = query(
    collection(db, COLLECTION_NAME),
    orderBy('createdAt', 'desc')
  );
  
  return onSnapshot(q, (snapshot) => {
    const opinions = snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    callback(opinions);
  }, (error) => {
    console.error('Error listening to opinions:', error);
  });
};

export const subscribeToVisibleOpinions = (callback) => {
  const q = query(
    collection(db, COLLECTION_NAME),
    where('isVisible', '==', true),
    orderBy('createdAt', 'desc')
  );
  
  return onSnapshot(q, (snapshot) => {
    const opinions = snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    callback(opinions);
  }, (error) => {
    console.error('Error listening to visible opinions:', error);
  });
}; 